@page {
    size: A4;
    margin: 20mm;
}

.container {
    padding: 20px;
    width: 100%;
    margin: 0;
    /* border: 1px solid rgba(0, 0, 0, 0.1); */
    border-radius: 8px;
    box-sizing: border-box;
    page-break-after: always;
}

.header {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #ccc;
    padding-bottom: 15px;
    margin-bottom: 20px;
}

.logoSection {
    text-align: left;
}

.invoiceSection {
    text-align: right;
}

.clientInfo {
    margin-bottom: 20px;
}

.clientInfo h3 {
    margin-bottom: 5px;
}

.productTable {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.productTable th,
.productTable td {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
}

.productTable th {
    font-weight: bold;
}

.summary {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
}

.footer {
    margin-top: 20px;
    text-align: center;
}

.footer h4 {
    margin-bottom: 10px;
}

.footer ul {
    list-style-type: none;
    padding: 0;
}

.footer ul li {
    margin: 5px 0;
}

/* Print media query */
@media print {
    body {
        width: 210mm;
        height: 297mm;
        margin: 0;
        padding: 0;
    }

    .container {
        width: 100%;
        margin: 0;
    }

    .header,
    .footer {
        page-break-inside: avoid;
    }

    .productTable {
        page-break-inside: auto;
    }

    .summary {
        page-break-before: auto;
    }

    .footer {
        position: fixed;
        bottom: 20px;
        width: 100%;
        text-align: center;
    }
}