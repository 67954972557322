.callNowBtn{
    width: 100%;
    position: fixed;
    bottom: 0px;
    left: 0px;
    height: 8svh;
    border-top: 1px solid #dfdfdf;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}