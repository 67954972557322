.mainWrapper{
    width: 100%;
    height: 91svh;
}
.mainHeader{
    height: 6svh;
    width: 96%;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 2%;
}
.headerCaption{
    width: 30%;

}
.actionHeader{
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.chooseStaffBtn button{
    display: flex;
    justify-content: center;
    align-items: center;
}
.dateHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dateBtn button{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #dfdfdf;
    padding: 4px 8px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;

    font-size: 0.7rem;
    font-weight: 600;
}

.eachBookingRow{
    display: flex;
    padding: 4px 8px;
    justify-content: space-between;
}
.mainBody{
    height: calc(85vh - 1px);
}

.selectAStaff{
    height: calc(85vh - 1px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.selectIcon img{
    width: 240px;
    height: 240px;
}
.selectTitle{
    padding: 10px 0px;
    text-align: center;
    color: #a5a5a5;
}