.container {
    position: relative;
    width: 100%;
    max-width: 400px;
    margin: auto;
}

.inputContainer {
    display: flex;
    align-items: center;
    background: #f7f9fc;
    border: 1.5px solid #d1d9e6;
    border-radius: 8px;
    padding: 3px 8px;
    transition: border-color 0.3s ease-in-out;
    width: 300px;
}

.inputContainer:focus-within {
    border: 1.5px solid #007bff;
}

.inputIcon {
    color: #555;
    margin-right: 8px;
}

.inputField {
    width: 100%;
    border: none;
    outline: none;
    background: transparent;
    font-size: 0.8rem;
    padding: 7px 0;
}

.dropdown {
    position: absolute;
    width: 100%;
    background: white;
    border: 1px solid #ccc;
    border-radius: 6px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 4px;
    overflow: hidden;
    z-index: 1000;
}

.dropdownItem {
    padding: 10px;
    cursor: pointer;
    transition: background 0.2s;
    display: flex;
    align-items: center;
}

.dropdownItem:hover,
.selected {
    background: #007bff;
    color: white;
}

.staffIcon {
    margin-right: 8px;
    color: inherit;
}

.hidden {
    display: none;
}