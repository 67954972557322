.staffSearch {
    position: relative;
}

.searchInput {
    width: 100%;
    padding: 8px;
    border-bottom: 1px solid #dfdfdf;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
}

.suggestionList {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    background: #fff;
    border: 1px solid #ccc;
    z-index: 10;
    margin: 0;
    padding: 0;
    list-style: none;
}

.suggestionItem {
    padding: 8px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.suggestionItem:hover,
.focused {
    background: #f0f0f0;
}

.staffDetails {
    display: flex;
    flex-direction: column;
}

.staffName {
    font-weight: bold;
    font-size: 0.8rem;
}

.staffRole {
    font-size: 0.7em;
    color: #666;
}

.selectedStaffContainer {
    margin-top: 16px;
}

.staffItem {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.contributionInput {
    margin-left: 8px;
    width: 60px;
    padding: 4px;
}

.addStaffButton {
    margin-top: 8px;
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.addStaffButton:hover {
    background-color: #0056b3;
}
