.uploadContainer {
    padding: 20px;
    margin: auto;
}
.headerWrapper{
    display: flex;
    justify-content: space-between;
    gap: 25%;
}
.uploadActions {
    margin-bottom: 20px;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
}
.initSection{
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;

}
.downloadBtn,
.fileUpload {
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}

.downloadBtn:hover {
    background-color: #0056b3;
}
.searchBar{
    flex: 3;
}
.searchBar input {
    width: calc(100% - 22px);
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 0.8rem;
}
.rowsPerPageSelector{
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.tableContainer {
    overflow-x: auto;
    margin-bottom: 20px;
    height: 60svh;
    overflow-y: scroll;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    font-size: 0.8rem;
}

table th,
table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
}

.pagination {
    text-align: center;
    margin-top: 10px;
}

.pagination button {
    padding: 5px 10px;
    margin: 0 5px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
}

.pagination button.active {
    background-color: #0056b3;
}

.submitBtn {
    padding: 10px 20px;
    border: none;
    background-color: green;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}

.submitBtn:hover {
    background-color: darkgreen;
}