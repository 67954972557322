.container {
    padding: 20px;
    background-color: #f9f9f9;
}

.header {
    text-align: center;
    margin-bottom: 40px;
}

.header h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 10px;
}

.header p {
    font-size: 1.2rem;
    color: #666;
}

.buttonsContainer {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 40px;
}

.buttonPrimary {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.buttonPrimary:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

.buttonSecondary {
    background-color: #6c757d;
    color: white;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.buttonSecondary:hover {
    background-color: #5a6268;
    transform: scale(1.05);
}

.featuresSection {
    display: flex;
    justify-content: space-around;
    gap: 20px;
    margin-bottom: 40px;
    text-align: center;
}

.feature {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;
}

.feature:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.featureIcon {
    font-size: 2rem;
    color: #007bff;
    margin-bottom: 10px;
}

.motivationSection {
    text-align: center;
    background-color: #e7f3ff;
    padding: 20px;
    border-radius: 10px;
}

.motivationSection h2 {
    font-size: 2rem;
    color: #007bff;
    margin-bottom: 10px;
}

.motivationSection p {
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 20px;
}

.motivationSection button {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.3s;
}

.motivationSection button:hover {
    transform: scale(1.05);
}

@media screen and (max-width: 768px) {
    .featuresSection {
        flex-direction: column;
        gap: 20px;
    }
}
