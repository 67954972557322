.mainWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.title{
    height: 7svh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 500;
}

.mainContent {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 25px;
}
.firstRowWrapper{
    display: flex;
    justify-content: space-between;
    width: 96%;
    padding: 0px 2%;
    gap: 20px;
}
.summaryWrapper {
    background-color: #f9f9f9;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 12px;
    max-width: 32%;
    width: 32%;
    overflow: hidden;
}

.chartTitle {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    margin-bottom: 16px;
    text-align: center;
}

.summaryContainer {
    padding: 16px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    gap: 16px;
}

.eachSummaryChip {
    background: white;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 8px;
    border-radius: 8px;
    padding: 16px;
    text-align: center;
    font-size: 14px;
    color: #555;
    transition: box-shadow 0.2s ease-in-out;
}

.eachSummaryChip:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 6px 12px;
    cursor: pointer;
}

.eachSummaryChip strong {
    color: #111;
    font-weight: 600;
}
.iconWrapper {
    font-size: 20px;
    margin-bottom: 8px;
    color: #007bff;
}
.membershipCurrentStatusWrapper{
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    max-width: 32%;
    flex: 1;
    overflow: hidden;
}
.mcswWrapper{
    padding: 0px 60px 40px;
}
.predictiveAnlaysisWrapper{
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    max-width: 32%;
    flex: 1;
    overflow: hidden;
}
.chartWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 96%;
    padding: 0px 2%;
    gap: 20px;
}
.chartContainer{
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    max-width: 32%;
    flex: 1;
    overflow: hidden;
}
.twoInARow{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 96%;
    padding: 0px 2%;
}
.doughnutWrapper{
    /* min-width: 300;
    max-width: 400px; */
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    width: 31%;
}
.chartContainerDn{
    padding: 30px;
}
.bubbleWrapper{
    /* min-width: 800px;
    max-width: 1200px; */
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    width: 66%;
}
.benchmarkingWrapper {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
}
.chartTitle{
    background-color: #dfdfdf;
    height: 6svh;
    display: flex;
    align-items: center;
    padding: 0px 3%;
    font-weight: 600;
    font-size: 0.9rem;
}
.chartContent{
    padding: 10px 20px 20px;
}
.benchmarkSection {
    margin-bottom: 15px;
}

.excellent {
    color: green;
}

.good {
    color: #007bff;
}

.withinRange {
    color: #6c757d;
}

.belowAverage {
    color: orange;
}

.poor {
    color: red;
}

.predictiveWrapper{
    padding: 20px;
}

.predictiveContainer{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 18px;
}