.mainHeader{
    height: calc(6svh - 1px);
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 1%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
}
.pageTitle{
    font-weight: 600;
}
.mainContainer{
    display: flex;
    height: 80svh;
}
.leftWrapper{
    flex: 1;
    
}
.backBtnWrapper{
    display: flex;
    align-items: center;
    gap: 6px;
}
.centerWrapper{
    flex: 3.25;
    border-left: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
    padding: 10px 10px;
    height: 87svh;
    overflow-y: scroll;
}
.rightWrapper{
    flex: 1.25;
}
.leftContainer{
    height: 87svh;
}
.titleWrapper{
    font-weight: 600;
    padding: 10px 5px;
    background-color:#DFDFDF;
    border-bottom: 1px solid #dfdfdf;
}
.themesWrapper{
    height: 40svh;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    border-bottom: 1px solid #dfdfdf;
    
}   

.eachThemeGroup{
    width: 95%;
    border-bottom: 3px solid #a5a5a529;
    padding: 0px 0px 0px 5%;
}

.themeGroupTitle{
    font-size: 0.8rem;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 2%;
    cursor: pointer;
    
}
.themeOption{
    padding: 12px 0px 12px 18px;   
    font-size: 0.8rem; 
    cursor: pointer;
    font-weight: 500;
}
.themeOption:hover{
    background-color: azure;
}
.themeOptionActive{
    padding: 12px 0px 12px 18px;   
    font-size: 0.8rem; 
    background-color: aliceblue;
    cursor: pointer;
    font-weight: 500;
}


.themesWrapper {
    width: 100%;
}
.choosePalleteTitle{
    background-color: #dfdfdf;
    font-weight: 600;
    padding: 10px 5px;
    border-bottom: 1px solid #dfdfdf;
}
.themeGroupTitle:hover {
    background-color: #e0e0e0;
}
.themeDataCollapsed {
    padding: 0;
}

.colorPalleteWrapper{
    height: 30svh;
    overflow-y: scroll;
}
.eachColorPallete{
    cursor: pointer;
}




.shareInvoiceWrapper{
    border-top: 3px solid #EDEDEE;
    padding: 10px 3% 30px;
}
.shareInvoiceTitle{
    font-size: 0.8rem;
    font-weight: 500;
    padding: 0px 0px 8px;
}
.menuWrapper{
    display: flex;
    gap: 10px;
    justify-content: space-between;
    padding: 0px 4%;
}
.eachMenu{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.menuTxt{
    font-size: 0.6rem;
}

.addMore{
    display: flex;
    justify-content: center;
    align-items: center;
}


.sendingInvoice {
    cursor: not-allowed;
}

.spinner {
    animation: spin 0.5s linear infinite;
    -webkit-animation: spin 0.5s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


.markAsCompleted button{
    padding: 8px 14px;
    background-color: aliceblue;
    border: 1px solid #027afb;
    font-size: 0.8rem;
    font-weight: 500;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}