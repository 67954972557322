.container {
    padding: 10px;
    color: #333;
}

h2 {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
}

.toolbar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    background-color: white;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    padding: 8px 12px;
}

.searchBar {
    display: flex;
    align-items: center;
    background-color: #f4f4f4;
    padding: 5px 10px;
    border-radius: 5px;
    width: 100%;
    max-width: 300px;
}

.searchBar input {
    border: none;
    outline: none;
    width: 100%;
    padding: 5px;
    font-size: 14px;
    background-color: transparent;
    margin-left: 8px;
}

.searchBar .icon {
    color: #888;
}

.table {
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    background-color: white;
}

.header {
    display: flex;
    background-color: #007bff;
    color: white;
    padding: 5px 0;
    font-weight: bold;
    text-align: left;
}
.tableFooter{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header .cell:first-child {
    flex: 0 0 40px; /* Adjusted for radio button column */
    text-align: center;
}

.cell {
    flex: 1;
    padding: 10px;
    text-align: left;
    cursor: pointer;
}
.templateName{
    flex: 3;
    padding: 10px;
    text-align: left;
    cursor: pointer;
    overflow: hidden;
}
.delSource{
    flex: 1.4;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
}
.body {
    background-color: white;
    height: 56svh;
    overflow-y: scroll;
}

.row {
    display: flex;
    border-bottom: 1px solid #ddd;
    transition: background-color 0.2s ease;
}

.row:hover {
    background-color: #f9f9f9;
}

.row .cell:first-child {
    flex: 0 0 40px; /* Adjusted for radio button column */
    text-align: center;
}

.row .cell {
    flex: 1;
    padding: 10px;
    text-align: left;
}

.radioColumn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.previewButton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 10px; /* Adjusted for alignment */
}

.previewButton:hover {
    background-color: #0056b3;
}

.pagination {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    margin-top: 10px;
}

.pagination button {
    border: none;
    background: #f4f4f4;
    margin: 0 5px;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    transition: background 0.3s ease;
}

.pagination button:hover {
    background: #007bff;
    color: white;
}

.pagination .active {
    background: #007bff;
    color: white;
}

.previewOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: flex-end;
    z-index: 1000;
}

.previewContent {
    background: white;
    width: 80%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.3);
    position: relative;
    padding: 20px;
}

.closeBtn {
    position: absolute;
    top: 15px;
    right: 15px;
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #333;
}

.variables {
    flex: 1;
    padding: 20px;
    border-right: 1px solid #ddd;
}

.variables h3 {
    margin-bottom: 10px;
}

.variableInput {
    margin-bottom: 10px;
}

.variableInput label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.variableInput input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 5px;
    outline: none;
}

.preview {
    flex: 2;
    padding: 20px;
}

.preview h3 {
    margin-bottom: 10px;
}

.whatsappTheme {
    background-color: #e1ffc7;
    border: 1px solid #25d366;
    padding: 15px;
    border-radius: 5px;
    color: #333;
    font-size: 14px;
    line-height: 1.5;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.smsTheme {
    background-color: #f4f4f4;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 5px;
    color: #333;
    font-size: 14px;
    line-height: 1.5;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
