.container {
    display: flex;
    height: calc(93svh - 3px);
    background-color: #f7f7f7;
}

.sidebar {
    width: 20%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    background-image: linear-gradient(119deg, #f4e5ec, #E1EEF8);
}

.sidebarItem {
    padding: 15px;
    cursor: pointer;
    margin: 10px 0;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    gap: 8px;
}

.sidebarItem.active {
    background-color: #4caf50;
    color: white;
}

.content {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
}

.footer {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    gap: 10px;
}

.footerBtn, .footerSaveBtn {
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.footerBtn:disabled, .footerSaveBtn:disabled {
    background-color: #d3d3d3;
    cursor: not-allowed;
}

.footerSaveBtn.saved {
    background-color: #8bc34a;
}

.progressContainer {
    margin: 20px 0;
    height: 10px;
    background-color: #f0f0f0;
}

.progressBarWrapper {
    width: 100%;
    height: 100%;
    background-color: #e0e0e0;
}

.progressBar {
    height: 100%;
    background-color: #4caf50;
}

.loadingIcon {
    margin-left: 10px;
    animation: spin 1s infinite linear;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
