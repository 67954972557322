.mainWrapper{
    height: 93svh;
    width: 100%;
    background-color: #252832;
    color: white;
}
.mainContainer{
    width: 100%;
    height: 93svh;
    display: flex;
    flex-direction: column;
}
.upperWrapper{
    height: 10svh;
    width: 100%;
    background-color: #F7F7F7;
    color: black;
}
.lowerWrapper{
    height: 83svh;
    width: 100%;
}
.leftVerStrip{
    width: 5%;
    height: 93svh;
    border-right: 1px solid rgb(139, 139, 139);
}
.centerPage{
    width: 62.5%;
    height: 93svh;
    border-right: 1px solid rgb(139, 139, 139);
}
.rightCol{
    width: calc(32.5% - 2px);
}

.mainHeader{
    height: 10svh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 1.5%;
}
.mainBody{
    height: 80svh;
    overflow-y: scroll;
}
.mhLeft{
    width: 50%;
}
.clientNameTitle{
    font-weight: 500;
    font-size: 0.8rem;
}
.clientName{
    font-weight: 600;
    font-size: 1.2rem;
}
.mobGenWrapper{
    display: flex;
    font-weight: 500;
    font-size: 0.7rem;
    gap: 12px;
    align-items: center;
}
.mobile{
    background-color: skyblue;
    padding: 4px 8px;
    border-radius: 5px;
    font-weight: 600;
}
.gender{
    background-color: magenta;
    padding: 4px 8px;
    border-radius: 5px;
    font-weight: 600;
}
.mhRight{
    width: 50%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}
.eachNavOption{
    height: 7svh;
    width: 9%;
    border: 1px solid #dfdfdf;
    background-color: white;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    cursor: pointer;
}
.enoIcon{
    height: 4.5svh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.enoTxt{
    height: 2.5svh;
    font-size: 0.7rem;
    text-align: center;
}
.searchBar{
    display: flex;
    align-items: center;
    border: 1px solid #dfdfdf;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    overflow: hidden;
}
.searchBar input{
    padding: 8px 20px;
    border: none;
    background-color: transparent;
    color: white;
    font-size: 0.8rem;
}
.searchIcon{
    padding: 0px 14px 0px 0px;
    display: flex;
    align-items: center;
}

.mhrOptions{
    display: flex;
}
.mhrEachOption{
    margin: 0px 0px 0px 10px;
    height: 30px;
    width: 30px;
    border: 1px solid #dfdfdf;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}
.cardDetailsSummaryWrapper{
    border: 1px solid rgb(139, 139, 139);
    margin: 0px 5% 10px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.singleRow{
    border: 1px solid #dfdfdf;
    margin: 0px 5%;
    height: 50svh;
}

.cdsTitle{
    display: flex;
    justify-content: space-between;
    padding: 30px 3% 10px;
}
.cdsTitleCaption{
    font-weight: 600;
}
.cardStrip{
    background-color: #E4EDE8;
    margin: 10px 3%;
    color: black;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}
.cardStripLeft{
    width: 50%;
}
.cardStripRight{
    display: flex;
    align-items: flex-end;
}
.csUpper{
    font-weight: 600;

}
.csUpperLoader{
    margin: 0px !important;
    padding: 0px !important;
}
.csValue{
    font-weight: 600;
    padding: 14px 0px;
    font-size: 1.8rem;
    display: flex;
    align-items: center;
}
.csLower{
    font-weight: 600;
    font-size: 0.8rem;
    text-transform: uppercase;
    color: #00000090;
}
.viewDetailsBtn{
    display: flex;
    align-items: center;
    background-color: #85B899;
    padding: 8px 15px;
    font-size: 0.8rem;
    font-weight: 600;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    cursor: pointer;
}


.boughtCardWrapper{
    /*background-color: #E4EDE8;
    */margin: 10px 3%;
    color: black;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    flex: 1;
    overflow-x: auto;
    padding: 0px 0px 20px 0px;
}
.boughtCardContainer{
    display: flex;
}
.thumbnail{
    margin-right: 10px !important;
    margin-bottom: 0px !important;
    border-radius: 10px !important;
    -webkit-border-radius: 10px !important;
    -moz-border-radius: 10px !important;
    -ms-border-radius: 10px !important;
    -o-border-radius: 10px !important;
}
.eachCardWrapper{
    height: 117px;
    width: 260px;
    background-color: gold;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    margin: 0px 10px 0px 0px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.cardName{
    padding: 0px 0px 10px 0px;
    font-weight: 600;
    letter-spacing: 1.5px;
    text-align: right;
    font-size: 0.7rem;
}
.cardNumber{
    display: flex;
    justify-content: space-between;
    width: 100%;
    letter-spacing: 1.3px;
    font-weight: 600;
    padding: 10px 0px;
}
.expiryDate{
    font-size: 0.6rem;
    font-weight: 500;
    letter-spacing: 1.2px;
    color: #00000090;
    text-transform: uppercase;
}
.buyNewCardWrapper{
    padding: 10px;
    height: 117px;
    border: 1px solid #dfdfdf;
    width: 260px;
    background-color: black;
    display: flex;
    color: rgba(255, 255, 255, 0.822);
    font-weight: 600;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}



.balanceSummaryWrapper{
    margin: 25px 3% 30px;
    border: 1px solid rgb(139, 139, 139);
    display: flex;
    justify-content: space-between;
    padding: 14px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}
.eachBs{
    padding: 10px 20px;
}
.bsCaption{
    font-size: 0.8rem;
    font-weight: 600;
    color: #A7A9AE;
}
.bsValue{
    padding: 10px 0px;
    font-weight: 600;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
}



