.mainWrapper{
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100svh;
    width: 100%;
    z-index: 444;
}
.mainContainer{
    position: relative;
    height: 100svh;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: #000000bf;
    display: flex;
}
.mainContent{
    background-color: white;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    height: 60svh;
    width: 40%;
    overflow: hidden;
}


.headerContent{
    height: 6svh;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    background-color: #EDF0F5;
}
.bodyContent{
    height: 42svh;
    overflow-y: scroll;
}

.fieldWrapper{
    padding: 18px 4%;
    display: flex;
    gap: 10px;
    flex-direction: column;
}
.eachInputWrapper{
    padding: 4px 0px;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    overflow: hidden;
}
.selectedUser{
    padding: 6px 10px;
    cursor: pointer;
}
.clientName{
    font-size: 0.9rem;
    font-weight: 600;
}
.clientMobile{
    font-size: 0.7rem;
    font-weight: 500;
}
.eachInputWrapper input{
    padding: 10px 10px;
    width: calc(100% - 20px);
    border: 0px solid transparent;
}
.eachInputWrapper textarea{
    padding: 10px 10px;
    width: calc(100% - 20px);
    border: 0px solid transparent;
}

.footerContent{
    height: 14svh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.footerContent button{
    padding: 10px 26px;
    border: 1px solid #027FFF;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    background-color: #027FFF20;
    color: #027FFF;
    font-weight: 500;
}
.closeBtn{
    font-weight: 500;
    padding: 10px 0px;
    font-size: 0.7rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
}


.eachInputWrapper:has(textarea:focus) {
    border-color: #007bff;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.25);
}
.eachInputWrapper:has(input:focus) {
    border-color: #007bff;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.25);
}
