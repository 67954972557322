.mainWrapper{
    padding: 2%;
}
.mainContainer{
    background-color: white;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}
.mainContent{
    display: flex;
    gap: 15px;
    flex-direction: column;
    padding: 2%;
}
.firstWrapper{
    display: flex;
    justify-content: space-between;
    gap: 20px;
}
.detailsWrapper{
    flex: 3;
    border: 1px solid #dfdfdf;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
}
.detailsContainer{
    padding: 20px;
    display: flex;
    gap: 10px;
    flex-direction: column;
}
.name{
    font-weight: 500;
    font-size: 1.4rem;
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.editIcon{
    height: 35px;
    width: 35px;
    border: 1px solid #dfdfdf;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #dfdfdf;
    cursor: pointer;
}
.role{
    font-weight: 500;
    font-size: 0.7rem;
}
.mobile{
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
    color: #818181;
    font-size: 0.8rem;
}
.email{
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
    color: #818181;
    font-size: 0.8rem;
}

.authWrapper{
    flex: 2;
    border: 1px solid #dfdfdf;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
}

.authContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
}
.notCreatedTitle{
    text-align: center;
    padding: 10px 5%;
}
.notCreatedTitle h2{
    font-weight: 600;
    text-align: center;
    color: #818181;
    font-size: 1rem;
}
.createdTitle{
    text-align: center;
    padding: 0px 20px;
}
.createdTitle h2{
    font-weight: 600;
    text-align: center;
    color: #818181;
    font-size: 0.8rem;
}
.tagWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}
.activeTag{
    padding: 4px 10px;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    -ms-border-radius: 18px;
    -o-border-radius: 18px;
    border: 1px solid green;
    font-size: 0.6rem;
    font-weight: 600;
    color: green;
}
.disableTag{
    padding: 4px 10px;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    -ms-border-radius: 18px;
    -o-border-radius: 18px;
    border: 1px solid red;
    font-size: 0.6rem;
    font-weight: 600;
    color: red;
}
.createBtnWrapper{
    text-align: center;
}
.actionWrapper{
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}
.createBtn{
    background-color: #027FFF;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border: 1px solid #027FFF;
    color: white;
    font-weight: 500;
    font-size: 0.8rem;
    padding: 9px 16px;
}
.deleteBtn{
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #dfdfdf20;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border: 1px solid #dfdfdf;
    color: #212121;
    font-weight: 500;
    font-size: 0.8rem;
    padding: 9px 16px;
    cursor: pointer;
}




.authActionWrapper{
    display: flex;
    flex: 1;
    border-top: 1px solid #dfdfdf;
    padding: 10px 2%;
    background-color: #dfdfdf20;
}
.authActionContainer{
    flex: 1;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    align-items: center;
}
.deactivateBtn{
    padding: 6px 12px;
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    font-size: 0.7rem;
    font-weight: 500;
    cursor: pointer;
}
.copyCredentials{
    padding: 6px 20px;
    border: 1px solid #027fff;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    font-size: 0.7rem;
    font-weight: 600;
    background-color: #027fff;
    color: white;
    cursor: pointer;
}
.credentialsCopied{
    padding: 6px 20px;
    border: 1px solid #81C22A;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    font-size: 0.7rem;
    font-weight: 600;
    background-color: #bff17d60;
    color: white;
    cursor: pointer;
    color: #81C22A;

}

.credentailsWrapper{
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 10px 5% 20px;
}

.displayName{
    text-align: center;
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 1;
    justify-content: space-between;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border: 1px solid #dfdfdf;
}
.caption{
    font-size: 0.7rem;
    font-weight: 500;
    color: #818181;
    flex: 1;
    padding: 12px 0px;
    border-right: 1px solid #dfdfdf;
    background-color: #dfdfdf70;
}
.value{
    font-weight: 500;
    font-size: 1.1rem;
    flex: 1;
    font-size: 0.7rem;
}