.searchDropdown {
    position: relative;
    width: 100%;
}

.searchInput {
    width: calc(100% - 24px);
    padding: 9px 12px;
    font-size: 0.7rem;
    border: 0px solid #ddd;
    outline: none;
    transition: box-shadow 0.3s;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.searchInput:focus {
    /* box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); */
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.suggestionsList {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    z-index: 1000;
    max-height: 20svh;
    overflow-y: scroll;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.suggestionItem {
    padding: 8px 12px;
    font-size: 0.7rem;
    color: #333;
    cursor: pointer;
    transition: background 0.2s;
}

.suggestionItem:hover,
.suggestionItem.active {
    background: #007bff;
    color: white;
}