/* StarPerformers.module.css */
.wrapper {
    padding: 0px 2rem;
    background: linear-gradient(to bottom, #ffffff, #f7f7fa);
    text-align: center;
    height: 86svh;
    overflow-y: scroll;
}

.title {
    font-size: 1rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 1rem;
}

.topPerformer {
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    padding: 2rem;
    margin-bottom: 3rem;
    position: relative;
    animation: glow 1.5s infinite;
}

@keyframes glow {

    0%,
    100% {
        box-shadow: 0px 0px 15px rgba(255, 223, 0, 0.6);
    }

    50% {
        box-shadow: 0px 0px 30px rgba(255, 223, 0, 0.9);
    }
}

.runnerUps {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 3rem;
}

.runnerCard {
    background: #fefefe;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    border-radius: 10px;
    width: 200px;
    text-align: center;
}

.leaderboard {
    max-width: 800px;
    margin: 0 auto;
}

.leaderboardRow {
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    background: #ffffff;
    border-bottom: 1px solid #eee;
    border-radius: 5px;
    margin-bottom: 0.5rem;
}

.medalIcon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}