.dropdownContainer {
    position: relative;
    width: 100%;
}

.searchInput {
    width: calc(100% - 18px);
    padding: 8px;
    font-size: 0.8rem;
    border: 0px solid #ccc;
    border-radius: 4px;
}

.suggestionsList {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 200px;
    overflow-y: auto;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    z-index: 1000;
}

.suggestionItem {
    padding: 8px;
    cursor: pointer;
    font-size: 14px;
}

.suggestionItem:hover,
.highlighted {
    background: #f0f0f0;
}

.noResults {
    padding: 8px;
    text-align: center;
    color: #999;
    font-size: 14px;
}
