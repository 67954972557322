.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.modal {
    background: #fff;
    width: 60%;
    max-width: 80%;
    height: 100vh;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    padding: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.closeBtn {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.content p {
    margin: 10px 0;
}

.status {
    padding: 5px 10px;
    border-radius: 5px;
    color: #fff;
}

.status.active {
    background: green;
}

.status.completed {
    background: blue;
}

.performance {
    padding: 5px 10px;
    border-radius: 5px;
    color: #fff;
}

.performance.excellent {
    background: green;
}

.performance.good {
    background: orange;
}

.performance.fair {
    background: red;
}

.toggleBtn {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.toggleBtn:hover {
    background-color: #0056b3;
}