.container {
    padding: 0px;
    line-height: 1.5;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.logoSection {
    text-align: left;
}

.logo {
    max-width: 80px;
    margin-bottom: 10px;
}

.invoiceSection {
    text-align: right;
}

.clientInfo {
    margin: 20px 0;
    font-size: 0.9rem;
    padding: 20px;
}
.productWrapper{
    width: calc(100% - 40px);
    padding: 20px;
}
.productTable {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.productTable th,
.productTable td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
}

.summary {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    padding: 0px 20px;
}

.footer {
    text-align: center;
    font-size: 0.85rem;
    margin-top: 20px;
    padding: 0px 20px;
}

.footer h4 {
    margin-bottom: 10px;
}

.footer ul {
    list-style: none;
    padding: 0;
}
