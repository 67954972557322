.mainWrapper {
    flex: 1;
}

.mainContainer {
    padding: 20px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    position: relative;
}

/* Header Styling */
.headerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Date Change Wrapper */
.dateChangeWrapper {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    cursor: pointer;
    gap: 8px;
    color: #858585;
    padding: 0 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    position: relative;
    transition: box-shadow 0.2s ease-in-out;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.dateChangeWrapper:hover {
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}

/* Current Date Display */
.currentDate {
    font-size: 0.8rem;
    color: #858585;
    font-weight: 500;
    min-width: 60px;
    text-align: center;
}

/* Date Picker Popup */
.datePicker {
    position: absolute;
    top: 110%;
    left: 50%;
    width: 220px;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
    padding: 12px;
    z-index: 100;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    transform: translateY(0);
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

/* Hidden state for popup */
.datePicker.hidden {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-5px);
}

/* Date Picker Header */
.datePickerHeader {
    text-align: center;
    font-weight: 500;
    font-size: 0.8rem;
    margin-bottom: 10px;
}

/* Month Grid */
.monthGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 6px;
    padding: 5px 0;
}

.monthItem {
    text-align: center;
    padding: 6px 0;
    cursor: pointer;
    font-size: 0.85rem;
    border-radius: 6px;
    transition: background 0.2s, color 0.2s;
    background-color: #f7f7f7;
}

.monthItem:hover {
    background: #027Aff;
    color: white;
}

/* Year Selector */
.yearSelector {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    gap: 10px;
}

.yearGrid {
    display: flex;
    gap: 8px;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    justify-content: center;
}

.yearItem {
    cursor: pointer;
    padding: 5px 12px;
    border: none;
    background: #f0f0f0;
    border-radius: 6px;
    font-size: 0.9rem;
    transition: background 0.2s;
    min-width: 40px;
    text-align: center;
}

.yearItem:hover {
    background: #027Aff;
    color: white;
}

/* Year Navigation Buttons */
.yearButton {
    cursor: pointer;
    padding: 6px 12px;
    border: none;
    background: #f0f0f0;
    border-radius: 6px;
    font-size: 1.2rem;
    transition: background 0.2s;
    font-weight: bold;
}

.yearButton:hover {
    background: #027Aff;
    color: white;
}

/* Calendar Icon */
.calendarIcon {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    background-color: white;
    cursor: pointer;
    border-radius:8px;
    -webkit-border-radius:8px;
    -moz-border-radius:8px;
    -ms-border-radius:8px;
    -o-border-radius:8px;
}

.calendarIconActive {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    background-color: #027Aff;
    cursor: pointer;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

/* Icons Wrapper */
.iconsWrapper {
    display: flex;
    gap: 10px;
}

/* Main Body */
.mainBody {
    height: 60svh;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    padding: 10px;
    background-color: white;
}

/* Main Footer */
.mainFooter {
    height: 6svh;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    padding: 5px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .mainContainer {
        padding: 15px;
    }

    .datePicker {
        width: 180px;
    }

    .yearGrid {
        gap: 5px;
    }

    .monthItem {
        font-size: 0.75rem;
    }
}

@media (max-width: 480px) {
    .mainContainer {
        padding: 10px;
    }

    .datePicker {
        width: 160px;
    }

    .monthItem {
        font-size: 0.7rem;
    }

    .yearItem {
        font-size: 0.8rem;
        padding: 4px 10px;
    }
}