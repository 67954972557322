.mainWrapper{
    flex: 1;
}
.mainContainer{
    padding: 10px 2%;
}
.mainHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.title{
    font-weight: 500;
    color: #212121;
}
.subTitle{
    font-weight: 500;
    font-size: 0.8rem;
    color: #848484;
}
.addNew button{
    padding: 4px 10px;
    font-size: 0.8rem;
    font-weight: 500;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    color: #848484;
}

.mainBody{
    flex: 1;
}
.tableWrapper{
    flex: 1;
    background-color: white;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
    overflow: hidden;
}
.tableHeader{
    flex: 1;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px 0px;
    border-bottom: 1px solid #dfdfdf60;
    background-color: #dfdfdf50;
    font-size: 0.8rem;
    font-weight: 500;
}
.tableBody{
    flex: 1;
    overflow-y: scroll;
    max-height: 70vh;
}
.eachRowWrapper{
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
    font-size: 0.8rem;
    padding: 9px 0px;
    border-bottom: 1px solid #dfdfdf40;
}





.name{
    flex: 3;
    padding: 0px 6px;
}
.email{
    flex: 4;
}
.phone{
    flex: 2;
}
.role{
    flex: 2;
}
.actions{
    flex: 6;
    padding: 0px 0px;
}
.eachRowWrapper .actions{
    padding: 6px 0px;
}
.actions a{
    padding: 6px 12px;
    background-color: #027FFF;
    color: white;
    font-weight: 500;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    font-size: 0.7rem;
}