.offerCard {
    width: calc(100% - 40px);
    margin: 0 auto;
    padding: 20px;
    border-radius: 15px;
    background-color: #fff5f8;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    color: #333;
    animation: fadeInUp 1s ease-in-out;
    height: 90svh;
}

.offerTitle {
    font-size: 1.8rem;
    color: #d12f52;
    font-weight: bold;
    text-align: center;
    margin-bottom: 0.5rem;
    animation: bounceIn 1s ease-out;
}

.offerSubtitle{
    font-size: 1.1rem;
    text-align: center;
    margin-bottom: 1rem;
}

.offerDescription {
    font-size: 1rem;
    text-align: center;
    margin-bottom: 1.5rem;
}

.highlight {
    color: #d12f52;
    font-weight: bold;
}

.offerList {
    list-style: square;
    padding-left: 1.5rem;
    margin-bottom: 1rem;
    animation: slideInLeft 1s ease-out;
}

.priceInfo {
    font-size: 1.1rem;
    text-align: center;
    margin-bottom: 1.5rem;
    animation: slideInRight 1s ease-out;
}

.originalPrice,
.discountedPrice {
    display: block;
}

.discountedPrice {
    color: #d12f52;
    font-weight: bold;
}

.locationInfo {
    font-size: 1rem;
    text-align: center;
    margin-bottom: 1rem;
    animation: fadeIn 1.5s ease-out;
}

.validityInfo,
.offerExpiry,
.disclaimer {
    font-size: 0.9rem;
    text-align: center;
    color: #555;
    animation: fadeIn 2s ease-out;
}

/* Animations */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideInLeft {
    from {
        opacity: 0;
        transform: translateX(-20px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideInRight {
    from {
        opacity: 0;
        transform: translateX(20px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes bounceIn {

    0%,
    100% {
        transform: scale(0.9);
        opacity: 0.7;
    }

    50% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}