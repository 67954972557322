.mainWrapper{
    padding: 2%;
    flex: 1;
    background-color: #F9F9F9;
}
.customerRegisterationForm input:focus{
    outline: #1D7AF4 !important;
    border: 1px solid #1D7AF4 !important;
}
.customerRegisterationForm textarea:focus{
    outline: #1D7AF4 !important;
    border: 1px solid #1D7AF4 !important;
}
.mainHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.storeName{
    font-weight: 500;
    font-size: 1.2rem;
    text-transform: capitalize;
}
.storeAddress{
    font-size: 0.7rem;
    font-weight: 500;
    color: #848484;
}
.addClientBulkRight{
    display: flex;
    gap: 10px;
    align-items: center;
}
.addClientsInBulk{
    padding: 10px 20px;
    color: #6b6b6b;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    font-weight: 600;
    font-size: 0.7rem;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.addClientsInBulk:hover{
    background-color: #1a57ad;
    color: white;
}
.viewClients{
    padding: 10px 20px;
    background-color: #1D7AF4;
    color: white;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    font-weight: 600;
    font-size: 0.7rem;
}

.customerRegisterationForm{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0px 0px 0px;
}
.crfContainer{
    background-color: white;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    padding: 10px 0px;
    width: 80%;
}
.crfTitle{
    text-align: center;
    font-weight: 500;
    font-size: 1.4rem;
    padding: 10px 0px;
}
.inputWrappers{
    display: flex;
    gap: 28px;
    padding: 30px;
    width: calc(100% - 60px);
}
.eachColInputWrapper{
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 18px;
}
.eachInputWrapper{
    display: flex;
    justify-content: space-between;
}
.countryCode{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}
.countryCode select{
    padding: 8px 0px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    width: 80px;
}
.phoneNumber{
    flex: 9;
    display: flex;
    justify-content: flex-end;
}
.phoneNumber input{
    height: 34px;
    width: 34px !important;

    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.nameInputWrapper{
    display: flex;
    gap: 10px;
    flex: 1;
    width: 100%;
}
.contactNumberWrapper{
    display: flex;
    gap: 10px;
}
.alreadyRegistered{
    font-size: 0.7rem;
    text-align: right;
}
.alreadyRegistered a{
    font-weight: 600;
}
.alternateNumber{
    flex: 1;
}
.alternateNumber input{
    width: calc(100% - 12px);
    padding: 10px 5px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.whatsAppNumber{
    flex: 1;
}
.whatsAppNumber input{
    width: calc(100% - 12px);
    padding: 10px 5px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.nameInputEach{
    flex: 1;
}
.nameInputWrapper input{
    padding: 10px 5px;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    width: calc(100% - 12px);
}
.genderWrapper{
    display: flex;
    gap: 10px;
}
.genderBtn{
    padding: 10px 10px;
    flex: 1;
    font-size: 0.8rem;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color: transparent;
    border: 1px solid #dfdfdf;
}
.genderBtnActive{
    padding: 9px 10px;
    flex: 1;
    border: 1px solid #1F7AF4;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-size: 0.8rem;
    background-color: #1F7AF4;
    color: white;
}
.emailInputWrapper input{
    padding: 12px 5px;
    width: calc(100% - 12px);
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.addressInputWrapper textarea{
    padding: 12px 5px;
    width: calc(100% - 10px);
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.dateInputWrapper{
    display: flex;
    gap: 10px;
}
.dateInputWrapper input{
    padding: 12px 5px;
}
.ageInputWrapper{
    flex: 1;
    display: flex;
    gap: 16px;
}
.yearsWrapper{
    flex: 1;
}
.monthsWrapper{
    flex: 1;
}
.ageInputWrapper select{
    padding: 10px 12px;
    width: 100%;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.dobWrapper{
    flex: 1;
}
.dobWrapper input{
    width: calc(100%  - 12px);
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.registerWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}
.registerWrapper button{
    background-color: #1E7BF4;
    color: white;
    font-weight: 500;
    padding: 12px ;
    width: 100%;
    border: none;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}


.cityStateWrapper{
    display: flex;
    flex: 1;
    gap: 10px;
}
.cityStateWrapper input{
    padding: 10px 6px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}