.container {
    padding: 20px;
    background-color: #f9f9f9;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.searchFilter {
    display: flex;
    gap: 15px;
    align-items: center;
}

.searchBar {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 8px 10px;
}

.searchBar input {
    border: none;
    outline: none;
    margin-left: 5px;
}

.filters select {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.loader {
    text-align: center;
    padding: 50px 0;
    color: #888;
}

.loaderIcon {
    font-size: 24px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

.table {
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.tableHeader, .tableRow {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    padding: 10px;
    border-bottom: 1px solid #eee;
    font-size: 0.8rem;
}

.tableHeader {
    background-color: #f4f4f4;
    font-weight: bold;
}
.tableBody{
    height: 64svh;
    overflow-y: scroll;
}
.tableRow:hover {
    background-color: #f9f9f9;
}

.paginationControls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.pagination {
    display: flex;
    gap: 5px;
}

.pageButton {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;
}

.pageButton:hover {
    background-color: #f4f4f4;
}

.activePage {
    background-color: #007bff;
    color: #fff;
    border-color: #007bff;
}


.exportBtn {
    padding: 10px 20px;
    margin: 10px 0;
    background: #28a745;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.exportBtn:hover {
    background: #218838;
}
