.container {
    width: 100%;
    background-color: #f3f3f3;
}

.bodyWrapper {
    display: flex;
    flex-direction: column;
    height: calc(88svh - 1px);
    overflow-y: scroll;
}

.firstRowWrapper {
    min-height: 26svh;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rowContainer {
    padding: 0px 1%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
}

.frLeftContainer {
    display: flex;
    gap: 5px;
    flex-direction: column;
}

.frleftUpper {
    display: flex;
    gap: 10px;
}

.inputFieldWrapper {
    min-width: 240px;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    background-color: white;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.inputFieldHolder {
    min-width: 240px;
    border-radius: 6px;
    background-color: white;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.inputFieldHolder input {
    height: 4svh;
    font-size: 0.8rem;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    width: calc(100% - 22px);
    border: 1px solid transparent;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.inputFieldHolder select {
    height: 4svh;
    font-size: 0.8rem !important;
    padding: 0px 10px !important;
    display: flex;
    align-items: center;
    width: calc(100% - 0px);
    border: 1px solid transparent;
}

.small {
    width: 160px !important;
}

.fieldTitleWrapper {
    margin-top: -14px;
    margin-left: 12px;
    font-size: 0.7rem;
    font-weight: 600;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.fieldTitleTxt {
    background-color: white;
    padding: 3px 10px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    color: #90949f;
}

.fieldBodyWrapper {
    width: 100%;
    margin-top: -1px;
}

.fieldBodyWrapper input {
    width: calc(100% - 24px);
    border: 0px solid #dfdfdf;
    padding: 8px 12px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    font-weight: 500;
    font-size: 0.75rem;
}

.fieldBodyWrapper textarea {
    width: calc(100% - 24px);
    border: 0px solid #dfdfdf;
    padding: 8px 12px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    font-weight: 500;
    font-size: 0.75rem;
}

.inputFieldWrapper:has(.fieldBodyWrapper input:focus) {
    border-color: #007bff;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.25);
}
.inputFieldWrapper:has(.fieldBodyWrapper input:focus) {
    border-color: #007bff;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.25);
}

.actionRowWrapper {
    width: 98%;
    padding: 0px 1%;
    height: 8svh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: white;
    border-top: 1px solid #d8d8d8;
    gap: 10px;
}

.frRightContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 0.8rem;
}

.frEachRow {
    display: flex;
    gap: 10px;
    align-items: center;
}

.frTitle {
    color: #828792;
    font-weight: 500;
    width: 140px;
}

.frInput input {
    border: none;
    border-bottom: 1px solid #82879260;
    background-color: transparent;
    width: 200px;
    padding: 8px 10px;
}

.frInput select {
    width: 220px;
    padding: 6px 10px !important;
    background-color: transparent;
}





.twoInARow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10%;
}

.eachInputWrapper {
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    margin-top: 10px;
    width: 100%;
}

.inputTitleWrapper {
    font-size: 0.8rem;
    margin-top: -7px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 0px 0px 2%;
    font-weight: 500;
}

.inputTitle {

    background-color: white;
    padding: 0px 10px;
    font-size: 0.7rem;
}



.productsPanel {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    background-color: white;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
}

.productsHeader {
    display: flex;
    align-items: center;
    font-size: 0.7rem;
    text-transform: uppercase;
    font-weight: 500;
    height: 5svh;
    border-bottom: 1px solid #dfdfdf;
}

.clientSuggestions {
    max-height: 200px;
    overflow-y: auto;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
    position: absolute;
    width: 100%;
    z-index: 10;
}

.suggestionItem {
    padding: 10px;
    cursor: pointer;
}

.suggestionItem:hover {
    background-color: #f0f0f0;
}

.searchInput {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
}

.productRow {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
}

.addRowButton {
    padding: 8px 12px;
    background-color: rebeccapurple;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

.addRowButton:hover {
    background-color: darkviolet;
}




.sn {
    width: 3%;
    border-right: 1px solid #dfdfdf;
    text-align: center;
    height: 5svh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.productItem {
    width: 24%;
    border-right: 1px solid #dfdfdf;
    height: 5svh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hsnSac {
    width: 8%;
    border-right: 1px solid #dfdfdf;
    height: 5svh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.qty {
    width: 5%;
    border-right: 1px solid #dfdfdf;
    height: 5svh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.uom {
    width: 10%;
    border-right: 1px solid #dfdfdf;
    height: 5svh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.price {
    width: 8%;
    border-right: 1px solid #dfdfdf;
    height: 5svh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.discount {
    width: 8%;
    border-right: 1px solid #dfdfdf;
    height: 5svh;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.discountIcon {
    height: 28px;
    width: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
    background-color: rebeccapurple;
    color: white;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    font-size: 0.9rem;
}

.gst {
    width: 8%;
    border-right: 1px solid #dfdfdf;
    height: 5svh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gstValueTxt {
    text-align: center;
    font-size: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.cal {
    width: 10%;
    border-right: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 5svh;

}

.total {
    width: 10%;
    height: 5svh;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #dfdfdf;
}

.deleteRow {
    width: 5%;
    height: 5svh;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.deleteRow button {
    background-color: transparent;
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.productRow input {
    width: calc(100% - 14px);
    padding: 6px 6px;
    border: 1px solid transparent;
    font-size: 0.8rem;
    text-align: center;
}

.productRow input:hover {
    /* border: 1px solid rebeccapurple; */
    background-color: rgb(233, 225, 241);
}

.productRow input:focus {
    background-color: rgb(233, 225, 241);

}

.productRow textarea {
    border: 1px solid transparent;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    font-size: 0.8rem;
    width: calc(100% - 22px);
    padding: 4px 10px;
}

.productRow textarea:hover {
    background-color: rgb(233, 225, 241);
    border-top: 1px solid rgb(203, 172, 235);
}

.productRow textarea:focus {
    border-top: 1px solid rgb(203, 172, 235);
    background-color: rgb(233, 225, 241);
}


input:focus {
    outline: none;
}

textarea:focus {
    outline: none;
}

.eachInputWrapper:has(.inputFieldWrapper input:focus) {
    border-color: #007bff;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.25);
}

.eachInputWrapper:has(.inputFieldWrapper textarea:focus) {
    border-color: #007bff;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.25);

}
.eachInputWrapper:has(.inputFieldWrapper input:hover) {
    border-color: #007bff7e;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.137);
}

.eachInputWrapper:has(.inputFieldWrapper textarea:hover) {
    border-color: #007bff7e;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.137);

}

.inputFieldWrapper textarea {
    padding: 7px 10px;
    border: 1px solid transparent;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    font-size: 0.7rem;
    font-weight: 500;
}

.noBorderWidthFull {
    border: 0px solid #dfdfdf !important;
}

.noBorderWidthFull input {
    border: 0px solid #dfdfdf !important;
    padding: 8px 10px !important;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    width: calc(100% - 20px);
}

.noBorderWidthFull textarea {
    border: 0px solid #dfdfdf !important;
    padding: 8px 10px !important;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    width: calc(100% - 20px);
}



.productRow .sn {
    width: 3%;
    border-right: 1px solid #dfdfdf;
    text-align: center;
    min-height: 5svh;
    display: flex;
    justify-content: center;

}

.productRow .productItem {
    width: 24%;
    border-right: 1px solid #dfdfdf;
    min-height: 5svh;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.productRow .productItem input {
    text-align: left;
}

.productRow .hsnSac {
    width: 8%;
    border-right: 1px solid #dfdfdf;
    min-height: 5svh;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.productRow .qty {
    width: 5%;
    border-right: 1px solid #dfdfdf;
    min-height: 5svh;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.productRow .uom {
    width: 8%;
    border-right: 1px solid #dfdfdf;
    min-height: 5svh;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.productRow .price {
    width: 8%;
    border-right: 1px solid #dfdfdf;
    min-height: 5svh;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.productRow .discount {
    width: 8%;
    border-right: 1px solid #dfdfdf;
    min-height: 5svh;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.productRow .gst {
    width: 8%;
    border-right: 1px solid #dfdfdf;
    min-height: 5svh;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.productRow .gst select {
    width: 100%;
    padding: 5px 8px;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border: 1px solid transparent;
}

.productRow .gst select:hover {
    border: 1px solid transparent;
    background-color: rgb(233, 225, 241);
}

.productRow .gst select:focus {
    outline: none;
    background-color: rgb(233, 225, 241);
}

.productRow .cal {
    width: 10%;
    border-right: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 5svh;
    height: 100%;
    flex-direction: column;
}

.staffItemWrapper {
    font-size: 0.6rem !important;
    border-top: 1px solid #dfdfdf;
}

.productRow .total {
    width: 10%;
    min-height: 5svh;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    border-right: 1px solid #dfdfdf;
    font-size: 0.8rem;
}

.productRow .deleteRow {
    width: 5%;
    min-height: 5svh;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.staffItem {
    display: flex;
    align-items: center;
    padding: 0px 4px;
}

.staffItem:not(:last-child) {
    border-bottom: 1px solid #dfdfdf;
}

.consultedByName {
    flex: 2;
}

.consultedByValue {
    flex: 1;
}

.gapRow {
    height: 20svh;
}

.itemsTotalWrapper {
    display: flex;
    border-bottom: 1px solid #dfdfdf;
    font-size: 0.8rem;
    font-weight: 600;
    background-color: rgb(233, 225, 241);
}

.totalInvoiceValue {
    width: calc(27% + 1px);
    border-right: 1px solid #dfdfdf;
    display: flex;
    justify-content: center;
    align-items: center;
}

.addNewRow {
    display: flex;
    padding: 5px 10px 10px;
    justify-content: flex-end;
}

.productRow input {
    height: 3svh;
    padding: 0px auto !important;
}




.itemsTotalWrapper {
    display: flex;
    border-bottom: 1px solid #dfdfdf;
    font-size: 0.8rem;
    font-weight: 600;
    background-color: rgb(233, 225, 241);
}

.totalInvoiceValue {
    width: calc(27% + 1px);
    border-right: 1px solid #dfdfdf;
    display: flex;
    justify-content: center;
    align-items: center;
}

.addNewRow {
    display: flex;
    padding: 5px 10px 10px;
    justify-content: flex-end;
}



.thirdRow {
    padding: 10px 1%;
}

.billingPanel {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    background-color: white;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.bpContainer {
    display: flex;
    justify-content: space-between;
    gap: 15%;
}

.bpLeft {
    flex: 1;
}

.bpRight {
    flex: 1;
}

.eachBpField {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 2%;
    width: 96%;
}

.eachBpField .twoInARow {
    width: 100%;
}

.tncWrapper {
    border-top: 1px solid #dfdfdf;
    padding: 6px 0px;
}



.tncHeader {
    font-size: 0.8rem;
    font-weight: 600;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.addNewBtn button {
    padding: 5px 8px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border: 1px solid #dfdfdf;
    font-size: 0.7rem;
}

.tncBody {
    padding: 8px 12px;
}

.eachBpField select {
    padding: 7px 10px;
}

.rowAction {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 0px;
    gap: 10px;
}

.rowAction button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    background-color: transparent;
}

.documentNotesWrapper {
    border-top: 1px solid #dfdfdf;
    padding: 8px 12px;
}


.rightTwoFields {
    padding: 0px 2%;
}

.eachbpRightRow {
    border-bottom: 1px solid #dfdfdf;
    padding: 12px 10px;
    font-size: 0.8rem;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
}

.eachPaymentRow {
    display: flex;
    gap: 10%;
    padding: 5px 0px;
}

.eprLeft {
    flex: 2;
    display: flex;
    align-items: center;
    gap: 10px;
}

.eprRight {
    flex: 1;
}

.customizedFields {
    padding: 10px 2%;
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.customFieldInput input {
    border: 1px dotted #dfdfdf;
    padding: 10px 8px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    font-size: 0.8rem;
}

.customFieldValue {
    display: flex;
    justify-content: flex-end;
}

.customFieldValue input {
    border: 1px solid #dfdfdf;
    padding: 10px 8px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    text-align: right;
    width: 60%;
    font-size: 0.8rem;
}

.percentRupeeToggle {
    display: flex;
    border: 1px solid #dfdfdf;
    border-radius: 36px;
    -webkit-border-radius: 36px;
    -moz-border-radius: 36px;
    -ms-border-radius: 36px;
    -o-border-radius: 36px;
    width: 15%;
    padding: 4px 5px;
    justify-content: space-between;
    font-weight: 600;
    font-size: 0.8rem;
    cursor: pointer;
}

.roundOffToggle {
    display: flex;
    border: 1px solid #dfdfdf;
    border-radius: 36px;
    -webkit-border-radius: 36px;
    -moz-border-radius: 36px;
    -ms-border-radius: 36px;
    -o-border-radius: 36px;
    width: 80px;
    padding: 4px 5px;
    justify-content: space-between;
    font-weight: 600;
    font-size: 0.8rem;
    cursor: pointer;
}

.roundOffToggle .activeToggle {
    width: 40px;
}

.roundOffToggle .defToggle {
    width: 40px;
}

.activeToggle {
    background-color: purple;
    color: white;
    height: 25px;
    width: 25px;
    border-radius: 24px;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    -ms-border-radius: 24px;
    -o-border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.defToggle {
    background-color: white;
    color: purple;
    height: 25px;
    width: 25px;
    border-radius: 24px;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    -ms-border-radius: 24px;
    -o-border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.roundOffWrapper {
    padding: 10px 2%;
    font-size: 0.8rem;
}

.roundOffContainer {
    display: flex;
    padding: 6px 0px;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #dfdfdf;
    font-size: 0.8rem;
}

.roundOffTxt {
    display: flex;
    align-items: center;
}

.roundOffTitle {
    width: 150px;
}

.grandTotalWrapper {
    padding: 10px 2%;
}

.grandTotalContainer {
    display: flex;
    padding: 10px 2%;
    background-color: rgb(233, 225, 241);
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;

}

.deleteIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    background-color: transparent;
}

.addBtnWrapper {
    display: flex;
    justify-content: flex-end;
}

.addBtnWrapper button {
    padding: 8px 10px;
    font-size: 0.8rem;
    font-weight: 500;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: 1px solid #dfdfdf;

}

.totalsInWrapper {
    padding: 0px 2% 10px;
    font-size: 0.8rem;
}

.totalsInContainer {
    background-color: rgb(238, 238, 238);
    padding: 10px 2% 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.totalInWordsTitle {
    font-weight: 600;
}

.totalInWrodsValue {
    font-weight: 500;
    text-transform: uppercase;
}

.paymentModeWrapper {
    padding: 0px 2% 10px;
    font-size: 0.8rem;
}

.paymentModeContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.paymentModes {
    display: flex;
    gap: 10px;
    align-items: center;

}

.eachPMode {
    border: 1px solid rebeccapurple;
    height: 4svh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 14px;
    cursor: pointer;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.eachPModeActive {
    border: 1px solid rebeccapurple;
    height: 4svh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 14px;
    background-color: rebeccapurple;
    color: white;
    cursor: pointer;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.thatsitRow {
    display: flex;
    height: 20svh;
    align-items: center;
    justify-content: center;
    color: #818181;
    font-size: 0.8rem;
    font-weight: 600;
}



.activeBtnWrapper {
    display: flex;
    gap: 10px;
}

.activeBtnWrapper button {
    border: 1px solid rebeccapurple;
}

.saveBtn {
    padding: 8px 10px;
    width: 100px;
    background-color: #027FFF20;
    border: 1px solid #027FFF;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    color: #027FFF;
    font-weight: 600;
    transition: opacity 0.3s ease;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
}

.saveBtn:disabled {
    cursor: not-allowed;
    opacity: 0.6;
    /* Makes button look disabled */
}

.saveAndPreviewBtn {
    padding: 8px 10px;
    width: 160px;
    background-color: #027FFF20;
    border: 1px solid #027FFF;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    color: #027FFF;
    font-weight: 600;
    transition: opacity 0.3s ease;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
}

.saveAndPreviewBtn:disabled {
    cursor: not-allowed;
    opacity: 0.6;
    /* Makes button look disabled */
}

.spinner {
    animation: spin 1s linear infinite;
    /* Adds spinning animation */
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.previewBtn {
    padding: 6px 10px;
    width: 120px;
    background-color: #027FFF20;
    border: 1px solid #027FFF;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    color: #027FFF;
    font-weight: 600;
    text-align: center;
}






.deleteStaffFromContri {
    cursor: pointer;
}


.remainingPayment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    font-size: 0.8rem;
    font-weight: 500;
}







/* Wrapper Styles */
.membershipWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
    justify-content: center;
    align-items: flex-start;
    background-color: #f9fafb;
    overflow-y: scroll;
}

/* Card Styles */
.card {
    width: 320px;
    background: #ffffff;
    border-radius: 16px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transform-origin: center;
}

.cardHeader {
    background: linear-gradient(45deg, #6a5acd, #8a2be2);
    color: #ffffff;
    padding: 15px;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.cardIcon {
    margin-right: 10px;
    font-size: 24px;
}

.cardDetails {
    padding: 20px;
    color: #333333;
    font-size: 14px;
}

.cardDetails div {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.icon {
    margin-right: 8px;
    color: #8a2be2;
}

.membershipWrapperSummary {
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    padding: 0px;
    background-color: white;
}

.mwsTitle {
    font-size: 0.6rem;
    font-weight: 600;
    padding: 5px 10px 5px;
}
.mswData{
    height: 80px;
    overflow-y: scroll;
}
.mswData table {
    margin: 0px !important;
}

.mswData table th {
    padding: 4px 10px !important;
}

.mswData table td {
    padding: 4px 10px;
    border: 0px solid red !important;
}

.mswData table th {
    border: 0px solid red !important;
}

.eachCardWrapper {
    display: flex;
    gap: 10px;
    font-size: 0.8rem;
}

.eachCardWrapper {
    width: 100%;
    border-collapse: collapse;
    font-size: 16px;
    text-align: left;
}

.eachCardWrapper th {
    background-color: #6a5acd;
    color: white;
    padding: 10px;
}

.eachCardWrapper td {
    padding: 8px 10px;
    border-bottom: 1px solid #ddd;
}

.eachCardWrapper tr:hover {
    background-color: #f2f2f2;
}

.eachCardWrapper th,
.eachCardWrapper td {
    text-align: left;
    white-space: nowrap;
}




.paymentStatus {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.8rem;
    font-weight: 500;
    padding: 10px;
    border-radius: 8px;
    margin: 10px 0;
    border: 1px solid;
}

.paymentStatus.advance {
    color: #2e7d32;
    background-color: #e8f5e9;
    border-color: #81c784;
}

.paymentStatus.remaining {
    color: #d32f2f;
    background-color: #ffebee;
    border-color: #e57373;
}

.paymentStatus.neutral {
    color: #757575;
    /* Grey for neutral */
    background-color: #f5f5f5;
    border-color: #bdbdbd;
}