.container {
    padding: 0px 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
}

.header {
    font-size: 1rem;
    color: #333;
    font-weight: 500;
    padding: 10px 0px 10px;
}

.inputGroup {
    margin-bottom: 20px;
}

.inputGroup label {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-bottom: 8px;
    color: #555;
}

.inputGroup .icon {
    margin-right: 8px;
    color: #4CAF50;
}

.input {
    width: calc(100% - 26px);
    padding: 12px;
    font-size: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 6px;
    transition: all 0.3s ease;
}

.textarea {
    width: calc(100% - 26px);
    padding: 12px;
    font-size: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 6px;
    min-height: 100px;
    transition: all 0.3s ease;
}

.nextBtn {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.nextBtn:hover {
    background-color: #45a049;
}

.nextBtn:active {
    background-color: #3c8e3c;
}
