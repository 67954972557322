.wrapper {
    display: flex;
    gap: 20px;
    padding: 20px;
  }
  
  .leftSection,
  .rightSection {
    flex: 1;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    background: #f9f9f9;
  }
  
  .leftSection h2,
  .rightSection h2 {
    margin: 0 0 16px;
    font-size: 1.5rem;
    color: #333;
  }
  
  .inputGroup {
    margin-bottom: 20px;
  }
  
  .inputGroup label {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .selector {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  .selector input {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    transition: border 0.3s ease;
  }
  
  .selector input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.4);
  }
  
  .clearButton {
    position: absolute;
    right: 10px;
    top: 10px;
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    color: #666;
  }
  
  .clearButton:hover {
    color: #000;
  }
  
  .dropdown {
    position: absolute;
    top: 110%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    transition: all 0.3s ease;
  }
  
  .dropdownItem {
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 0.95rem;
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s ease, color 0.3s ease;
  }
  
  .dropdownItem:last-child {
    border-bottom: none;
  }
  
  .dropdownItem:hover {
    background: #f5f5f5;
  }
  
  .dropdownItem.selected {
    background: #e6f7ff;
    font-weight: bold;
    color: #007bff;
    display: flex;
    justify-content: space-between;
  }
  
  .dropdownItem.selected::after {
    content: '✔';
    color: #007bff;
    font-size: 1rem;
    margin-left: 8px;
  }
  
  .highlighted {
    background: #d9ecff !important;
  }
  
  .additionalInfo {
    font-size: 0.85rem;
    color: #888;
  }
  
  .noResults {
    padding: 10px;
    text-align: center;
    color: #aaa;
    font-style: italic;
  }
  
  .selectAll {
    padding: 10px;
    font-weight: bold;
    cursor: pointer;
    color: #007bff;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: background 0.3s ease;
  }
  
  .selectAll:hover {
    background: #f0f9ff;
  }
  
  .rightSection p {
    margin: 0 0 12px;
    font-size: 1rem;
    color: #333;
  }
  