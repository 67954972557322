.mainWrapper {
    width: 100%;
    position: relative;
}

.mainWrapper input {
    width: calc(100% - 22px);
    padding: 12px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}


.suggestionListWrapper {
    position: absolute;
    top: 100%;
    left: 0;
    width: calc(100% - 2px);
    background: white;
    border: 1px solid #ccc;
    max-height: 150px;
    overflow-y: auto;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    z-index: 5;
}

.inputBox{
    border: 1px solid #dfdfdf;
}
.inputBox:focus {
    border-color: #007bff;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.25);
}