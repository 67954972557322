/* SleekInvoiceTheme.module.css */

.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.companyDetails {
    text-align: left;
}

.invoiceDetails {
    text-align: right;
}

.productTable {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
}

.productTable th,
.productTable td {
    padding: 12px;
    border: 1px solid #ccc;
    text-align: left;
}

.productTable thead th {
    text-transform: uppercase;
    font-size: 14px;
}

.summarySection {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}

.summaryDetails,
.summaryValues {
    text-align: right;
}

.footer {
    text-align: center;
    margin-top: 20px;
    font-size: 12px;
    color: #888;
}