/* Base Wrapper */
.eachNavWrapper {
    position: relative;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 10px 12px;
    border-radius: 8px;
    transition: all 0.3s ease;
    background: linear-gradient(135deg, #f9fafb, #f4f5f7);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
    cursor: pointer;
    /* overflow: hidden; */
}

.eachNavWrapper:hover {
    background: linear-gradient(135deg, #ffffff, #e7ebf0);
    transform: translateY(-2px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12);
}
.eachNavContainer{
    display: flex;
    align-items: center;
}
/* Small (Collapsed) Wrapper */
.small {
    justify-content: center;
    padding: 10px;
}

/* Icon Styling */
.navIcon {
    animation: iconHover 1.5s infinite ease-in-out;
}

@keyframes iconHover {

    0%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }
}

/* Text Styling */
.navTxt {
    font-size: 0.8rem;
    color: #444;
    font-weight: 500;
    margin-left: 5px;
}

/* Hover Message */
.hoverMessage {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(-20%, -50%);
    background: #ffffff;
    color: #333;
    font-size: 12px;
    border: 1px solid #e0e0e0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 8px 12px;
    border-radius: 6px;
    white-space: nowrap;
    opacity: 0;
    /* visibility: hidden; */
    transition: opacity 0.4s ease, transform 0.4s ease;
    display: flex;
    align-items: center;
    gap: 8px;
    z-index: 14440;
}

/* Hover Message when collapsed */
.hoverSmall {
    left: 50%;
    top: 130%;
    transform: translate(-50%, 0);
}

/* Display hover message on hover */
.eachNavWrapper:hover .hoverMessage {
    opacity: 1;
    visibility: visible;
    transform: translate(-10%, -50%);
}

/* Info Icon in Hover Message */
.infoIcon {
    font-size: 16px;
    color: #6366f1;
}