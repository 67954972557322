.container {
    padding: 20px;
    background-color: #f9f9f9;
}

.filters {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
}

.filterBtn,
.sortBtn {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 8px 12px;
    background: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
}

.filterBtn:hover,
.sortBtn:hover {
    background: #0056b3;
}

.table {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.staffCard {
    background: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 220px;
}

.noData {
    font-size: 14px;
    color: gray;
}