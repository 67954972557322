.calendarContainer {
    background-color: white;
}
.controls{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    border-bottom: 1px solid #dfdfdf;
    padding: 8px 10px;
}
.controls select{
    font-size: 0.7rem !important;
    padding: 8px 10px !important;
    font-weight: 500;
}
.controlsLeft{
    flex: 1;
    display: flex;
    align-items: center;
    gap: 10px;
}
.controlsRight{
    flex: 1;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
}
.controlsRight a{
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #027FFF;
    color: white;
    padding: 9px 12px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    font-size: 0.8rem;
}
.calendarWrapper{
    padding: 0px 10px;
}
h1 {
    text-align: center;
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
}

/* Custom styling for event colors */
.rbc-event {
    border-radius: 5px !important;
    padding: 4px !important;
    font-size: 14px !important;
    color: white !important;
}

/* Status Color Coding */
.rbc-event.active {
    background-color: #4CAF50 !important; /* Green */
}

.rbc-event.upcoming {
    background-color: #FFC107 !important; /* Yellow */
}

.rbc-event.cancelled {
    background-color: #F44336 !important; /* Red */
}

/* Staff-Specific Row Colors */
.rbc-resource-header {
    background-color: #e0e0e0 !important;
    color: #333;
    font-weight: bold;
}

/* Apply unique colors per staff */
.rbc-resource-header:nth-child(1) { background-color: #FFEBEE; } /* Light Red */
.rbc-resource-header:nth-child(2) { background-color: #E3F2FD; } /* Light Blue */
.rbc-resource-header:nth-child(3) { background-color: #E8F5E9; } /* Light Green */
.rbc-resource-header:nth-child(4) { background-color: #FBE9E7; } /* Light Orange */

/* Modal Styling */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modalContent {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    width: 40%;
    text-align: center;
    position: relative;
}

.modalContent h2 {
    font-size: 20px;
    color: #333;
    margin-bottom: 10px;
}

.modalContent p {
    font-size: 16px;
    color: #555;
    margin: 5px 0;
}

.modalContent button {
    background: #ff4757;
    color: white;
    border: none;
    padding: 8px 15px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;
    transition: background 0.3s ease-in-out;
}

.modalContent button:hover {
    background: #e04040;
}

/* Enhancing the buttons */
.buttonContainer {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 15px;
}

.buttonContainer button {
    padding: 8px 12px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
}

.exportButton {
    background: #2196F3;
    color: white;
    border: none;
}

.exportButton:hover {
    background: #1976D2;
}

.filterButton {
    background: #4CAF50;
    color: white;
    border: none;
}

.filterButton:hover {
    background: #388E3C;
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.modalContent {
    animation: fadeIn 0.3s ease-in-out;
}







.resourceHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f0f0f0;
    padding: 8px 12px;
    border-bottom: 2px solid #ddd;
    font-size: 0.8rem;
    font-weight: bold;
}

.appointmentCount {
    font-size: 14px;
    font-weight: normal;
    color: #666;
    margin-left: 8px;
}

.resourceHeader button {
    background: #4CAF50;
    color: white;
    border: none;
    padding: 4px 8px;
    cursor: pointer;
    border-radius: 4px;
}

.resourceHeader button:hover {
    background: #45a049;
}






.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #000000bf;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 400;
}

.modalContent {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.modalContent input{
    width: calc(100% - 18px);
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 5px;
} 
.modalContent select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.modalActions {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.saveBtn {
    background: #4CAF50 !important;
    color: white;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 5px;

    display: flex;
    align-items: center;
    gap: 10px;
    width: 100px;
    text-align: center;
    justify-content: center;
}

.saveBtn:hover {
    background: #45a049;
}
.cancelBtn{
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100px;
    text-align: center;
    justify-content: center;
}
.deleteBtn{
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100px;
}


.servicesNotes{
    font-size: 0.8rem;
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    min-height: 100px;
    padding: 10px;
    background-color: #e5e5e540;
}



.timeWrapper{
    display: flex;
    gap: 10px;
}
.eachDate{
    flex: 1;
    background-color: #EDF0F5;
    padding: 5px ;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.caption{
    font-size: 0.7rem;
    font-weight: 500;
}
.dateValue{
    font-size: 0.8rem;
    font-weight: 500;
    padding: 7px;
}