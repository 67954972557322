.listWrapper{
    height: 58svh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 5px 2px;
}
.eachDayWrapper{
    display: flex;
    gap: 16px;
    height: 10svh;
}
.titleWrapper{
    flex: 1;
    text-align: right;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    height: 10svh;
    gap: 5px;
}
.detailWrapper{
    flex: 10;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    /* background-color: white; */
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    padding: 10px;
}
.day{
    font-size: 0.8rem;
    font-weight: 500;
}
.date{
    font-size: 0.8rem;
    font-weight: 600;
    color: #858585;
}

.highlight {
    background-color: rgba(255, 223, 186, 0.8);
    border: 2px solid #ff8c00;
    font-weight: bold;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}



.detailWrapper{
    display: flex;
}
.firstInWrapper{
    flex: 1;
}
.timelineWrapper{
    flex: 5;
}
.lastOutWrapper{
    flex: 1;
}
.hoursWorkedWrapper{
    flex: 1;
}