.mainWrapper {
    height: 100svh;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: #00000070;
}

.mainContainer {
    width: 100%;
    height: 100svh;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.contentWrapper {
    padding: 0px 14px;
    width: 50%;
    height: calc(100% - 28px);
}

.mainContent {
    background-color: #EDF0F5;
    height: 96svh;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 14px;
    height: calc(100% - 28px);
    gap: 14px;

}

.headerWrapper {
    height: 4svh;
    display: flex;
    align-items: center;
    font-weight: 500;
}

.addEntryWrapper {
    height: 26svh;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    width: 100%;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    background-color: white;
    padding: 10px 0px 20px;
}
.entriesWrapper{
    height: 30svh;
}
.entryFormWrapper {
    display: flex;
    gap: 40px;
    padding: 20px;
    width: 80%;
}

.eachEntryWrapper {
    display: flex;
    flex-direction: column;
    border: 1px solid #dfdfdf;
    gap: 10px;
    width: 100%;
}

.eachEntryWrapper input {
    padding: 10px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    width: calc(100% - 22px);
    font-size: 0.8rem;
    font-weight: 500;
}

.eachEntryWrapper textarea {
    border: 1px solid #dfdfdf;
    width: calc(100% - 22px);
    padding: 10px;
    font-size: 0.8rem;
    font-weight: 500;
    color: #8f8f8f;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.addEntryContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: column;
}

.entryActionBtnWrapper {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.saveBtn {
    width: 80px;
    background-color: #027aff;
    color: white;
    border: 1px solid #027aff;
    padding: 6px;
    font-size: 0.7rem;
    font-weight: 500;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.cancelBtn {
    width: 80px;
    background-color: white;
    color: #858585;
    border: 1px solid #dfdfdf;
    padding: 6px;
    font-size: 0.7rem;
    font-weight: 500;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

.entriesWrapper {
    height: 40svh;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    width: 100%;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    background-color: white;
}

.footerWrapper {
    height: 10svh;
    background-color: white;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;

    display: flex;
    align-items: center;
    gap: 20px;
    padding: 0px 20px;
    justify-content: space-between;
}

.borderWrapper {
    display: flex;
    gap: 80px;
    align-items: center;
    color: #232323;
}

.checkInWrapper {
    border-left: 3px solid green;
    padding: 0px 0px 0px 10px;
}

.checkOutWrapper {
    border-left: 3px solid red;
    padding: 0px 0px 0px 10px;
}

.totalWrapper {
    border-left: 3px solid #232323;
    padding: 0px 0px 0px 10px;
}

.titleCaption {
    font-size: 0.9rem;
    font-weight: 600;
}

.titleValue {
    font-size: 0.8rem;
    font-weight: 600;
    color: #858585;
    padding: 6px 0px 0px 0px;
}