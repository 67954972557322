.tabWrapper {
    display: flex;
    align-items: center;
    gap: 0px;
    background-color: white;
    position: relative;
    justify-content: space-between;
    padding-top: 4px;
    height: 4svh;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
}

.tabScroller {
    display: flex;
    overflow-x: auto;
    flex-grow: 1;
    scrollbar-width: thin;
    scrollbar-color: #c0c0c0 transparent;
    max-width: 90vw;
    align-items: center;
}

.tabScroller::-webkit-scrollbar {
    height: 6px;
}

.tabScroller::-webkit-scrollbar-thumb {
    background-color: #c0c0c0;
    border-radius: 4px;
}

.outerWrapper {
    display: inline-flex;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden; /* Ensures the child is clipped to the parent's border */
}
.tab {
    display: flex;
    align-items: center;
    background-color:#fff;
    padding: 0px 10px;
    min-width: 120px;
    cursor: grab;
    overflow: hidden;
    border-right: 2px solid white;
    height: 4svh;
}
.activeTab {
    display: flex;
    align-items: center;
    background-color: #F3F3F3;
    padding: 0px 10px;
    min-width: 120px;
    cursor: grab;
    height: 4svh;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

    border-top: 1px solid #dfdfdf;
    border-left: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
}

/* .tabContent {
    flex-grow: 1;
    font-size: 0.7rem;
    color: #333;
    text-decoration: none;
    max-width: 140px;
    overflow: hidden;
    font-weight: 500;
} */
.tabContent {
    flex-grow: 1;
    font-size: 0.8rem;
    color: #333;
    text-decoration: none;
    max-width: 140px;
    overflow: hidden;
    font-weight: 500;

    /* Required properties for text ellipsis */
    white-space: nowrap;  /* Prevents text from wrapping */
    text-overflow: ellipsis; /* Adds "..." when text overflows */
    display: block; /* Ensures proper behavior */
}



.closeIcon {
    color: #747474;
    cursor: pointer;
    margin-left: 8px;
}

.scrollButton {
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: #333;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.addButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #027FFF;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    margin: 0px 6px;
    border-radius: 35px;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    -ms-border-radius: 35px;
    -o-border-radius: 35px;
}

.addButton:hover {
    background-color: #45a049;
}



