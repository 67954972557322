.reviewsHolderWrapper {
    display: flex;
    flex-direction: column;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.rwHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.selectWrapper {
    flex: 1;
}

.selectBox {
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ddd;
}

.optionWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    font-size: 0.8rem !important;
    justify-content: space-between;
    width: 100%;
}

.defaultBtn {
    padding: 8px 13px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 0.7rem !important;
    display: flex;
    align-items: center;
    gap: 6px;
}

.defaultBtn:hover {
    background-color: #f0f0f0;
}

.selectedBtn {
    padding: 8px 13px;
    background-color: #d4edda;
    border: 1px solid #c3e6cb;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 0.7rem !important;
    display: flex;
    align-items: center;
    gap: 6px;
}

.reviewCount {
    font-size: 1rem;
    font-weight: bold;
    color: #333;
    display: flex;
    align-items: center;
    gap: 5px;
}

.rwBody {
    overflow-y: auto;
    max-height: 70vh;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.reviewList {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.loadingWrapper {
    text-align: center;
    font-size: 1.2rem;
    color: #555;
}




