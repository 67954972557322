.container {
    padding: 0px;
}
.controlsWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.searchAudienceWrapper{
    flex: 1;
}
.searchInput {
    padding: 10px;
    width: calc(100% - 22px);
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
}
.controlsBtn{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.controlsBtn a{
    background-color: #027aff;
    color: white;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    font-size: 0.8rem;
    padding: 9px 12px;
    font-weight: 500;
}
.audienceList {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.audienceCard {
    width: 250px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    background-color: white;
    transition: all 0.3s ease;
}

.audienceCard:hover {
    background-color: #f0f0f0;
}

.selected {
    border-color: #4caf50;
    background-color: #e8f5e8;
}

.preview {
    margin-top: 10px;
    font-size: 0.9em;
    color: #555;
}

.selectedAudience {
    margin-top: 30px;
    border-top: 2px dashed #ccc;
    padding-top: 20px;
}

.selectedPreview {
    margin-top: 10px;
}

.nextBtn {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.nextBtn:hover {
    background-color: #45a049;
}
