.corporateWrapper {
    background-color: #f7fafd;
    color: #003366;
    padding: 20px;
    max-width: 800px;
    border: 2px double #003366;
    margin: 0 auto;
}

.header h1 {
    color: #003366;
}

.lineItems table {
    border-collapse: collapse;
    width: 100%;
}
