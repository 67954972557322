.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f9f9f9;
    height: 93svh;
    width: 100%;
}
.header {
    font-weight: 500;
    color: #333;
    height: 7svh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dfdfdf;
    width: 98%;
    gap: 10px;
    padding: 0px 1%;
}
.hTitle{
    display: flex;
    align-items: center;
    gap: 5px;
}
.columnsWrapper{
    display: flex;
    width: 100%;
    background-color: white;
    height: calc(86svh - 1px);
}
.leftColumnWrapper{
    flex: 1;
    border-right: 1px solid #dfdfdf;
    overflow-y: scroll;
}
.centerColumnWrapper{
    flex: 3;
    border-right: 1px solid #dfdfdf;
}
.rightColumnWrapper{
    flex: 1.25;
}
.leftColumnContainer{
    display: flex;
    flex-direction: column;
}
.section {
    width: calc(100% - 40px);
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.sectionTitle{
    font-size: 0.8rem;
    font-weight: 500;
}

.audienceCategory{
    padding: 0px 0px 0px 10px;
}

.campaignHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 2%;
    border-bottom: 1px solid #dfdfdf;
}
.audienceName{
    font-size: 0.9rem;
    font-weight: 500;
}
.audienceDescription{
    font-size: 0.7rem;
}
.leftHeader{
    flex: 8;
}
.rightHeader{
    flex: 1;
}
.getDataBtn{
    padding: 6px 12px;
    border-radius: 36px;
    -webkit-border-radius: 36px;
    -moz-border-radius: 36px;
    -ms-border-radius: 36px;
    -o-border-radius: 36px;
    font-size: 0.7rem;
    border: 1px solid #dfdfdf;
    font-weight: 500;
}
select{
    font-size: 0.8rem !important;
}

.inputField {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
}

.button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #0056b3;
}

.previewContainer {
    margin-top: 20px;
    padding: 10px;
    border-top: 1px solid #eee;
}






.tableHeader{
    display: flex;
    gap: 10px;
    align-items: center;
    height: calc(5svh - 1px);
    border-bottom: 1px solid #dfdfdf;
    background-color: #dfdfdf45;
    font-size: 0.8rem;
    font-weight: 600;
}
.eachDataRow{
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 0.8rem;
    font-weight: 500;
    padding: 7px 0px;
    color: #818188;
}
.eachDataRow:not(:last-child) {
    border-bottom: 1px solid #dfdfdf;
}
.tableData{
    height: 70svh;
    overflow-y: scroll;
}
.noRecordsFound{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    height: 20svh;
    color: #818188;
}
.checkBox{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5%;
    cursor: pointer;
}
.clientName{
    width: 25%;
}
.mobile{
    width: 20%;
}
.gender{
    width: 12%;
}
.totalSpent{
    width: 6%;
}
.totalVisits{
    width: 6%;
}

.toolbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px;
    height: 5svh;
    gap: 10px;
}
.searchBoxWrapper{
    flex: 2;
}
.searchBox{
    padding: 0px 6px;
    height: 5svh;
    border: 0px solid;
    background-color: rgb(241, 241, 241);
    width: calc(100% - 12px);
}
.paginationControls{
    flex: 2;
    font-size: 0.8rem;
    display: flex;
    gap: 8px;
    align-items: center;
}
.paginationControls select{
    padding: 3px 6px !important;
}

.selectedDataPopup{
    position: fixed;
    bottom: 6svh;
    background-color: white;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    /* height: 6svh; */
    width: 340px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #037BFF;
    color: white;
    font-size: 0.8rem;
    font-weight: 500;
}
.sdpContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding: 10px 3%;
    width: 94%;
}
.sdpContainer button{
    padding: 10px 8px;
    background-color: white;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    border: 1px solid white;
    font-weight: 500;
    font-size: 0.8rem;
}
.pagination{
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 0.8rem;
    padding: 0px 1%;
}
.pagination button{
    padding: 4px 6px;
    font-size: 0.7rem;
    font-weight: 500;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}



.rightColumnContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}







.headerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 16px;
    cursor: pointer;
    background-color: #f9f9f9;
    font-weight: 500;
    transition: background-color 0.3s ease;
}

.headerWrapper:hover {
    background-color: #e0f7fa;
}

.categoryTitle {
    font-size: 16px;
}

.audienceCategory {
    padding: 10px 20px;
    background-color: #fff;
}

.subCategory {
    display: flex;
    align-items: center;
    padding: 10px 0;
    cursor: pointer;
    border-bottom: 1px solid #e0e0e0;
    transition: background-color 0.3s ease, transform 0.2s;
}

.subCategory:last-child {
    border-bottom: none;
}

.subCategory:hover {
    background-color: #f0f0f0;
}

.radioButtonIcon {
    margin-right: 10px;
    color: #007bff;
}

.audienceName {
    font-size: 14px;
    font-weight: 500;
}