.invoiceWrapper {
    /* font-family: "Arial", sans-serif; */
    color: #333;
    /* background-color: #f9f9f9; */
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    /* border: 1px solid #ddd; */
    border-radius: 5px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

/* Header */
.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    border-bottom: 2px solid #0047ab;
    padding-bottom: 10px;
}

.companyInfo h1 {
    font-size: 24px;
    color: #0047ab;
    margin: 0;
}

.invoiceDetails {
    text-align: right;
}

.invoiceDetails h2 {
    font-size: 20px;
    color: #333;
    margin: 0;
}

/* Customer Info */
.customerInfo {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.customerInfo h3 {
    margin: 0 0 5px 0;
    color: #0047ab;
}

/* Job Info */
.jobInfo table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.jobInfo th,
.jobInfo td {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
}

.jobInfo th {
    background-color: #0047ab;
    color: #fff;
}

/* Line Items */
.lineItems table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.lineItems th,
.lineItems td {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
}

.lineItems th {
    background-color: #0047ab;
    color: #fff;
}

/* Summary */
.summary .totals p {
    text-align: right;
    margin: 5px 0;
    font-size: 16px;
}

/* Footer */
.footer {
    text-align: center;
    font-size: 12px;
    color: #777;
    margin-top: 20px;
}

.footer p {
    margin: 5px 0;
}