.mainWrapper {
    padding: 20px;
}

.mainContainer {
    display: flex;
    /* box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; */
    background-color: white;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    flex-direction: column;
    /* border: 1px solid #eeeeee; */
}

.mainBody {
    display: flex;
}

.leftColWrapper {
    /* border-right: 1px solid #eeeeee; */
    flex: 1;
    padding: 0px 15px 20px;
}

.centerColWrapper {
    flex: 2.5;
    /* border-right: 1px solid #eeeeee; */
    padding: 0px 15px 20px;
}

.rightColWrapper {
    flex: 1;
    padding: 0px 15px 20px;
}

.profilePicWrapper {
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.profilePicWrapper img {
    width: 100%;
    display: flex;
}


.sectionTitle {
    font-size: 0.7rem;
    text-transform: uppercase;
    font-weight: 600;
    color: #5a5a5a;
    padding: 14px 0px;
}

.profilePicContainer {
    height: 200px;
    width: 200px;
}

.profilePicContainer img {
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}


.profileActionBtn {
    display: flex;
    align-items: center;
    font-size: 0.7rem;
    color: #027aff;
    gap: 7px;
    padding: 10px 0px;
    font-weight: 500;
    justify-content: center;
}




.eachInputSection {
    background-color: #ededf3;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    padding: 6px 10px;
}

.inputTitle {
    font-size: 0.6rem;
    font-weight: 500;
    color: #5a5a5a;
    padding: 0px 0px 4px;
}

.inputField {
    width: 100%;
}

.inputField input {
    width: calc(100% - 0px);
    padding: 3px 0px;
    background-color: transparent;
    border: 0px solid;
    font-size: 0.7rem;
    font-weight: 500;
}

.inputField textarea {
    width: calc(100% - 0px);
    padding: 3px 0px;
    background-color: transparent;
    border: 0px solid;
    font-size: 0.7rem;
    font-weight: 500;
}

.inputField select {
    width: calc(100% - 0px);
    padding: 3px 0px;
    background-color: transparent;
    border: 0px solid;
    font-size: 0.7rem;
    font-weight: 500;
}

.inputFieldsWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.addressWrapper {
    padding: 0px 10px;
}

.rolesWrapper {
    padding: 0px 10px;
}



.mainFooter {
    /* border-top: 1px solid #eeeeee; */
    padding: 10px 2%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.saveChangesBtn {
    font-size: 0.8rem;
    background-color: #027aff;
    color: white;
    padding: 12px 26px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border: none;
    font-weight: 500;
}





.inputField {
    position: relative;
    width: 100%;
}

.inputBox {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.suggestionList {
    position: absolute;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 10;
}

.suggestionList li {
    padding: 10px;
    cursor: pointer;
    font-size: 0.7rem;
    color: #333;
    font-weight: 500;
}

/* Hover Effect */
.suggestionList li:hover {
    background-color: #f0f0f0;
    color: black !important;
}

/* Highlighted selection */
.highlighted {
    background-color: #007bff;
    color: white !important;
}

.noResult {
    padding: 10px;
    color: grey;
    text-align: center;
}




.buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.saveChangesBtn {
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
    display: flex;
    gap: 8px;
    width: 200px;
    justify-content: center;
    align-items: center;
}

.saveChangesBtn:hover {
    background-color: #0056b3;
}

.saveChangesBtn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}
.spinnerWrapper {
    display: inline-block;
}
.spinnerIcon {
    font-size: 18px;
    display: inline-block;
    vertical-align: middle;
}
