.subCategory{
    font-size: 0.8rem;
    color: #818181;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}


.mainWrapper {
    border-bottom: 1px solid #dfdfdf;
    overflow: hidden;
    background-color: #fff;
    transition: all 0.3s ease;
}

.headerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 16px;
    cursor: pointer;
    background-color: #f9f9f9;
    font-weight: 500;
    transition: background-color 0.3s ease;
}

.headerWrapper:hover {
    background-color: #e0f7fa;
}

.categoryTitle {
    font-size: 16px;
}

.audienceCategory {
    padding: 10px 20px;
    background-color: #fff;
}

.subCategory {
    display: flex;
    align-items: center;
    padding: 10px 0;
    cursor: pointer;
    border-bottom: 1px solid #e0e0e0;
    transition: background-color 0.3s ease, transform 0.2s;
}

.subCategory:last-child {
    border-bottom: none;
}

.subCategory:hover {
    background-color: #f0f0f0;
}

.radioButtonIcon {
    margin-right: 10px;
    color: #007bff;
}

.audienceName {
    font-size: 14px;
    font-weight: 500;
}
