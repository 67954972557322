.mainWrapper{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100svh;
    z-index: 550;
}

.filtersPanelWrapper{
    width: 100%;
    height: 100vh;
    z-index: 5;
    position: fixed;
    top: 0px;
    left: 100%;
    background-color: #00000050;
    display: inline-flex;
    justify-content: flex-end;
}


.mainLeft{
    background-color: #f1f1f1;
    height: 100svh;
    width: 35%;
    position: absolute;
    right: 0;


    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.bodyContent{
    position: relative;
}
.bodyTitle{
    height: calc(6svh - 1px);
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #dfdfdf;
    background-color: #000000;
    color: white;
    font-weight: 600;

}

.formBody{
    padding: 10px 10%;
    height: 94svh;
    display: flex;
    overflow-y: scroll;
    justify-content: center;
    flex-direction: column;
}

.phoneNumberWrapper{
    padding: 14px 0px 0px;
}
.phoneTitle{
    padding: 8px 0px;
    font-weight: 600;
    font-size: 0.8rem;
}
.phoneNumberContainer{
    color: black;
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* background-color: white; */
}
.phoneNumberContainer input{
    color: black;
    padding: 8px 10px;
    border: none;
    width: 100% !important;
}
.phoneInputBox{
    width: 100%;
}
.alreadyRegistered{
    font-size: 0.7rem;
    padding: 4px 0px;
    text-align: right;
}
.alreadyRegistered a{
    color: #027FFF;
    font-weight: 500;
}
.nameWrapper{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 0px;
}
.eachNamePart{
    flex: 1;
}
.eachNamePartTitle{
    padding: 0px 0px;
    font-size: 0.8rem;
    font-weight: 600;
}
.eachNamePartInputWrapper{
    width: 100%;
}
.eachNamePartInputWrapper input{
    width: calc(92% - 2px);
    padding: 9px 4%;
    font-size: 0.8rem;
    border: none;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    margin: 4px 0px;
}
.eachNamePartInputWrapper select{
    padding: 8px 4%;
}
.emailWrapper{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 0px;
}
.emailWrapper .eachNamePart{
    width: 100%;
}
.emailWrapper .eachNamePartInputWrapper{
    width: 100%;
}
.emailWrapper input{
    width: calc(92% - 2px);
    padding: 11px 4%;
    font-size: 0.8rem;
    border: none;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    margin: 4px 0px;
}
.emailWrapper .eachNamePartInputWrapper input{
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.ageInputWrapper{
    display: flex;
    gap: 16px;
}
.yearsWrapper{
    flex: 1;
}
.monthsWrapper{
    flex: 1;
}
.ageInputWrapper select{
    padding: 10px 12px;
    width: 100%;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.dobWrapper{
    padding: 10px 0px;
}
.dobWrapper input{
    padding: 10px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.genderWrapper{
    width: 100%;
    padding: 10px 0px;
}
.genderTitle{
    padding: 0px 0px;
    font-size: 0.8rem;
    font-weight: 600;
}

.genderBtnWrapper{
    display: flex;
    justify-content: space-between;
    padding: 5px 0px;
}
.genderBtnDefault{
    width: 22%;
    padding: 6px 0px;
    border: 1px solid #dfdfdf;
    font-size: 0.8rem;
    font-weight: 600;
    background-color: #ffffff;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.genderBtnSelected{
    width: 22%;
    padding: 6px 0px;
    border: 1px solid #dfdfdf;
    font-size: 0.8rem;
    font-weight: 600;
    background-color: #2e2e2e;
    color: white;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}



.addressWrapper{
    padding: 10px 0px;
}
.addressBoxWrapper{
    width: 100%;
}
.addressBoxWrapper textarea{
    width: calc(96% - 2px);
    padding: 6px 2%;
    margin: 4px 0px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    max-height: 240px;
    overflow: scroll;
    resize: none;
}
textarea:focus{
    outline: none;
}




.eachOptionWrapper{
    width: 30%;
}
.anniversaryOptionTitle{
    padding: 0px 0px;
    font-size: 0.8rem;
    font-weight: 600;
}
.anniversaryOptions{
    display: flex;
    padding: 10px 0px;
}
.anniversaryOption{
    width: 100%;
    padding: 5px 0px;
}
.anniversaryOption select{
    width: 100%;
    padding: 6px 0px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
select:focus{
    outline: none;
}


.eachOptionWrapper{
    padding: 20px 0px;
}

.addNowBtn{
    padding: 9px 0px;
    width: 140px;
    background-color: #027FFF;
    border: 1px solid #027FFF;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    color: white;
    font-weight: 600;
}
.addNowBtnDull{
    padding: 9px 0px;
    width: 140px;
    background-color: #027FFF;
    border: 1px solid #027FFF;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    color: white;
    font-weight: 600;
    opacity: 0.6;
}
.clientDetails{
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.clientName{
    font-weight: 500;
    font-size: 1rem;
}
.address{
    font-size: 0.7rem;
    color: #818181;
    padding: 0px 0px 5px;
}
.phone{
    display: flex;
    gap: 10px;
    font-size: 0.7rem;
    font-weight: 500;
}
.memberCount{
    text-align: center;
    font-size: 0.8rem;
    padding: 0px 0px 10px;
}
.relativesWrapper{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.eachRelative{
    display: flex;
    gap: 3px;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 10px;
}
.erUpper{
    display: flex;
    gap: 10px;
    align-items: center;
}
.erLower{
    display: flex;
    gap: 10px;
    align-items: center;
}
.rFullName{
    font-weight: 600;
}
.rAge{
    font-size: 0.8rem;
    color: #818181;
}
.rGender{
    font-size: 0.8rem;
    color: #818181;
}
.rtfh{
    padding: 4px 10px;
    border: 1px solid #027FFF;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    font-size: 0.6rem;
    text-transform: capitalize;
}