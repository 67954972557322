.mainWrapper{
    padding: 2%;
    background-color: #f9f9f9;
}
.mainHeader{
    display: flex;
    gap: 20px;
}
.detailsWrapper{
    flex: 3;
    background-color: white;
    border: 1px solid #dfdfdf;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
    margin: 0px 0px 10px;
    padding: 1%;
}
.displayName{
    font-weight: 600;
    font-size: 1.2rem;
    color: #027FFF;
}
.role{
    font-size: 0.6rem;
    font-weight: 500;
    color: #818181;
}
.loginWrapper{
    flex: 1;
    background-color: white;
    border: 1px solid #dfdfdf;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
    margin: 0px 0px 10px;
    text-align: center;
}
.username{
    padding: 10px 10px;
    font-size: 0.8rem;
    font-weight: 500;
    color: black;
}
.loginEnabled{
    font-weight: 500;
    color: #027FFF;
    padding: 0px 0px 10px;
    font-size: 0.8rem;
}
.loginDisabled{
    font-weight: 500;
    color: red;
    padding: 0px 0px 10px;
    font-size: 0.8rem;
}
.tabOption{
    display: flex;
    gap: 10px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    padding: 0px 0px 10px;
}
.eachTabOption{
    cursor: pointer;
    padding: 4px 10px;
    font-size: 0.7rem;
    background-color: white;
    font-weight: 500;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.eachTabOptionActive{
    cursor: pointer;
    padding: 4px 10px;
    font-size: 0.7rem;
    font-weight: 500;
    border: 1px solid #027FFF;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    background-color: #027FFF20;
    color: #027FFF;
}
.featureTable{
    background-color: white;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
    overflow: hidden;
    border: 1px solid #dfdfdf;
}
.featureHeader{
    display: flex;
    padding: 12px 0px;
    background-color: #dfdfdf50;
    gap: 10px;
    font-weight: 500;
    font-size: 0.8rem;
    border-bottom: 1px solid #dfdfdf;
}
.tableDataWrapper{
    height: 65svh;
    overflow-y: scroll;
}
.eachFeatureRow{
    display: flex;
    gap: 10px;
    padding: 8px 0px;
    border-bottom: 1px solid #dfdfdf80;
    font-weight: 500;
    font-size: 0.8rem;
    color: #474747;
}

.storeDetailsCol{
    flex: 0.4;
    display: flex;
    justify-content: center;
    padding: 0px 10px;   
    flex: 8;
    flex-direction: column;
}
.storeAddress{
    font-size: 0.6rem;
}


.sn{
    flex: 0.4;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 0px 10px;
}
.featureLabel{
    flex: 3;
    display: flex;
    align-items: center;
}
.pageUrl{
    flex: 7;
    display: flex;
    align-items: center;
}
.isAccessible{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}