.mainWrapper {
    background-color: aliceblue;
    overflow-y: scroll;
    height: calc(93svh - 1px);
}
.mainHeader{
    height: 6svh;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 2%;
}
.pageTitle{
    font-size: 1rem;
    font-weight: 500;
}
.mhRight a{
    padding: 9px 18px;
    background-color: #2196F3;
    font-size: 0.8rem;
    font-weight: 500;
    cursor: pointer;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    color: white;
}
.mainContainer {
    width: 100%;
    overflow-y: scroll;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column-reverse;
}

.mainBodyWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.leftCol {
    width: 100%;
    height: 93svh;
    border-right: 1px solid #dfdfdf;
}

.headerWrapper {
    width: 100%;
    height: calc(8svh - 1px);
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.optionsWrapper{
    display: flex;
    align-items: center;
    gap: 10px;
}
.upperHeaderWrapper {
    background-color: white;
    width: 95%;
    height: calc(4svh - 1px);
    display: flex;
    padding: 0px 0px 0px 5%;
    align-items: center;
    font-weight: 600;
    color: #3a3a3a;
}

.lowerHeaderWrapper {
    width: 100%;
    display: flex;
    background-color: white;
    justify-content: space-between;
    height: 6svh;
}

.inputSearchWrapper {
    width: 32%;
    padding: 0px 3% 0px 5%;
}

.inputSearchWrapper input {
    padding: 8px 2%;
    width: calc(96% - 2px);
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background-color: #dfdfdf80;
}

.optionsWrapper {
    width: 96%;
    padding: 0px 2%;
    display: flex;
    justify-content: space-between;
}
.inputWrapper input{
    padding: 8px 10px;
    width: calc(100% - 22px);
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.eachFilterWrapper {
    display: flex;
    flex-direction: column;
    width: 30%;
}

.eachFilterCaption {
    font-size: 0.7rem;
    font-weight: 700;
    padding: 0px 0px 0px 5px;
    color: #919191;
    text-transform: uppercase;
    letter-spacing: 1.2px;
}

.eachFilterOption select {
    padding: 2px 0px;
    font-size: 0.8rem;
    width: 100%;
    border: 1px solid transparent;
    /* border: 1px solid chartreuse; */
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-weight: 600;
    color: #3a3a3a;
    cursor: pointer;
}

.eachFilterOption select:focus {
    outline: none;
}

.bodyWrapper {
    display: flex;
}

.verticalColWrapper {
    width: 5%;
    border-right: 1px solid #dfdfdf;
    background-color: white;
}

.verticalRightColWrapper {
    width: calc(100% - 1px);
}

.rightCol {
    flex: 1;
    width: 100%;
}

.addNewMemberWrapper a {
    font-size: 0.8rem;
    padding: 8px 14px;
    background-color: #FB651F;
    color: white;
    border: 1px solid #FB651F;
    font-weight: 600;
}







.tableWrapper {
    width: 92%;
    height: 74svh;
    padding: 2svh 4%;
    border: 1px solid #dfdfdf;
}

.tableContainer {
    /* border: 1px solid #dfdfdfa4; */
    /* height: calc(82svh - 2px); */
    height: 78svh;
    /* background-color: #bebebe; */
    background-color: #bebebe24;
}

.tableHeader {
    width: 100%;
    display: flex;
    align-items: center;
    height: calc(6svh - 1px);
    border-bottom: 1px solid #dfdfdf;
    background-color: #3a3a3a;
    color: white;
}

.tableBody {
    height: 70svh;
    overflow-y: scroll;
}

.eachTableRow {
    height: calc(6svh - 1px);
    border-bottom: 1px solid #dfdfdf;
    display: flex;
}

.indexCol {
    width: calc(4% - 1px);
    border-right: 1px solid #dfdfdf;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(6svh - 1px);
}

.cardNumberCol {
    width: calc(25% - 21px);
    border-right: 1px solid #dfdfdf;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(6svh - 1px);
    padding: 0px 10px;
}

.cardNumberTxt {
    font-weight: 700;
}

.clientName {
    font-size: 0.7rem;
}

.membershipType {
    width: calc(15% - 21px);
    border-right: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    height: calc(6svh - 1px);
    padding: 0px 10px;
}

.cardType {
    font-size: 0.8rem;
    font-weight: 500;
}

.servicesWorthCol {
    width: calc(10% - 21px);
    border-right: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    height: calc(6svh - 1px);
    padding: 0px 10px;
}

.paidAmountCol {
    width: calc(10% - 21px);
    border-right: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    height: calc(6svh - 1px);
    padding: 0px 10px;
}

.activatedOnCol {
    width: calc(18% - 21px);
    border-right: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    height: calc(6svh - 1px);
    padding: 0px 10px;
}

.actionCol {
    width: calc(14% - 21px);
    border-right: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    height: calc(6svh - 1px);
    padding: 0px 10px;
}

.noCardMembersFound {
    height: 40svh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.expiringIn {
    font-size: 0.7rem;
    font-weight: 600;
}

.expiringInTxt {
    color: green;
}

.expiredTxt {
    color: red;
}





.filters select {
    margin: 10px;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table th,
.table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.pagination {
    display: flex;
    justify-content: center;
    margin: 10px 0;
}

.spinner {
    animation: spin 1s linear infinite;
    font-size: 24px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.charts {
    margin-top: 20px;
}


.addNewBtn a{
    padding: 10px 18px;
    background-color: #2196F3;
    color: white;
    font-size: 0.8rem;
    font-weight: 500;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}