.mainWrapper{
    padding: 2%;

}
.mainContainer{
    background-color: white;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 14px;
}

.actionWrapper{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 0px 10px;
}
.newBookingBtn{
    padding: 6px 14px;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    font-weight: 500;
    font-size: 0.6rem;
}
