/* Overlay style */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Popup container */
.popup {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    position: relative;
    text-align: center;
}

/* Close button */
.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    color: #333;
}

/* Spinner for loading state */
.spinner {
    animation: spin 1s linear infinite;
    display: inline-block;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Headings */
.popup h2 {
    margin: 0 0 10px;
    font-size: 1.5rem;
    color: #333;
}

/* Paragraphs */
.popup p {
    margin: 0 0 20px;
    font-size: 1rem;
    color: #666;
}

/* Actions container */
.actions {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

/* Buttons */
.deleteButton,
.completeButton,
.cancelButton {
    background-color: #e74c3c;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 180px;
}

.completeButton {
    background-color: #27ae60;
}

.cancelButton {
    background-color: #95a5a6;
    margin-top: 10px;
    width: 100%;
}

.deleteButton:disabled,
.completeButton:disabled,
.cancelButton:disabled {
    cursor: not-allowed;
    opacity: 0.6;
}

.deleteButton:hover:not(:disabled) {
    background-color: #c0392b;
}

.completeButton:hover:not(:disabled) {
    background-color: #229954;
}

.cancelButton:hover:not(:disabled) {
    background-color: #7f8c8d;
}
