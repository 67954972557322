.createNewCampaign {
    background-color: #4f46e5;
    color: white;
    font-size: 1.5rem;
    border: none;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: transform 0.2s, background-color 0.2s;
}

.createNewCampaign:hover {
    background-color: #3730a3;
    transform: scale(1.1);
}

.popupOverlay {
    position: fixed;
    inset: 0;
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
}

.popupContainer {
    position: relative;
    width: 24rem;
    padding: 1.5rem;
    background-color: #ffffff;
    border-radius: 1.25rem;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
    animation: popupEntrance 0.3s ease-in-out;
}

@keyframes popupEntrance {
    from {
        opacity: 0;
        transform: scale(0.9);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

.closeButton {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background: none;
    border: none;
    color: #4b5563;
    cursor: pointer;
    font-size: 1.5rem;
    transition: color 0.2s;
}

.closeButton:hover {
    color: #000000;
}

.inputField {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #d1d5db;
    border-radius: 0.5rem;
    font-size: 1rem;
    transition: border-color 0.2s, box-shadow 0.2s;
}

.inputField:focus {
    outline: none;
    border-color: #4f46e5;
    box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.3);
}

.errorMessage {
    color: #dc2626;
    font-size: 0.875rem;
    margin-top: 0.25rem;
}

.submitButton {
    width: 100%;
    padding: 0.75rem;
    background-color: #4f46e5;
    color: white;
    border: none;
    border-radius: 0.5rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s, transform 0.2s;
}

.submitButton:hover {
    background-color: #3730a3;
    transform: scale(1.02);
}