.listWrapper{
    height: 80svh;
    overflow-y: scroll;
}
.eachRow{
    display: flex;
    gap: 10px;
}
.client_name{
    width: 20%;
}
.phoneNumber{
    width: 15%;
}