.mainWrapper{
    width: 100%;
    height: 100svh;
    background-color: floralwhite;
    color: white;
    display: flex;
    justify-content: space-between;
}
.leftSection{
    width: 60%;
    height: 100svh;
    
}
.leftContainer{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 100svh;
    width: 80%;
    color: black;
    flex-direction: column;
    text-align: left;
    padding: 0px 10%;
}
.rightSection{
    width: 40%;
    height: 100svh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.loginSection{
    padding: 0px 25%;
    width: 50%;
}
.loginResponse{
    color: #303030;
    font-weight: 600;
    text-align: right;
}
.inputWrapper{
    width: 100%;
    padding: 18px 0px;
    /* border: 1px solid #d4d4d4; */
}
.inputWrapper input{
    padding: 12px 2%;
    width: calc(96% - 2px);
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
    background-color: transparent !important;
}

.passwordWrapper{
    width: 100%;
    padding: 18px 0px;
}
.passwordContainer{
    width: calc(94% - 2px);
    display: flex;
    justify-content: center;
    background-color: #E9EFF6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    padding: 0px 4% 0px 2%;
    background-color: white;
    border: 1px solid #d4d4d4;

}
.togglePass{
    cursor: pointer;
}

.passwordWrapper input{
    padding: 12px 0px;
    width: calc(100% - 2px);
    border: 1px solid transparent;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.loginBtnWrapper{
    width: 100%;
    padding: 18px 0px;
}
.loginBtnWrapper button{
    background-color: #FB651F;
    color: white;
    font-weight: 700;
    letter-spacing: 1.2px;
    padding: 12px 2%;
    width: calc(100% - 0px);
    text-transform: uppercase;
    border: 1px solid transparent;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.loginBtnWrapper button:focus{
    outline: none;
}

.dividerLineWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0px;
}
.justLine{
    border: 1px solid rgb(167, 166, 166);
    display: flex;
    width: 50%;
}
.txtBetweenLine{
    padding: 0px 10px;
    font-weight: 600;
    width: 180px;
    text-align: center;
    color: black;
}


@media only screen and (max-width: 600px) {
    .mainWrapper{
        flex-direction: column;
    }
    .leftSection{
        width: 100%;
        height: 50svh;
    }
    .rightSection{
        width: 100%;
        height: 50svh;
    }
    .leftContainer{
        height: 50svh;
    }
    .loginSection{
        padding: 0px 10%;
        width: 80%;
    }
    .leftContainer p{
        font-weight: 500;
        color: #4e4e4e;
        font-size: 0.8rem;
        text-align: justify;
    }
}