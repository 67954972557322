.mainWrapper{
    height: calc(5svh - 1px);
    border-bottom: 1px solid #dfdfdf80;
    display: flex;
    align-items: center;
    padding: 0px 1%;
    background-color: white;
}
.tab{
    font-weight : 500;
    font-size: 0.7rem;
    padding: 0px 20px;
    text-transform: uppercase;
    color: #a5a5a5;
    height: calc(5svh - 2px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid transparent;
}
.activeTab{
    border-bottom: 2px solid #027aff;
    color: white;
    font-weight : 500;
    font-size: 0.7rem;
    padding: 0px 20px;
    color: black;
    height: calc(5svh - 2px);
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
}



