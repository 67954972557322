.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.sidebar {
    width: 80%;
    height: 100svh; /* 100% of the small viewport height */
    box-shadow: -4px 0 8px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    position: relative;

    background: linear-gradient(135deg, #ffffff, #f5f5f5); /* Main background */
}

.sidebar::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 1;

    /* Border gradient setup */
    background: 
        linear-gradient(to right, #ff7e5f, #feb47b) top,
        linear-gradient(to left, #6a11cb, #2575fc) bottom,
        linear-gradient(to bottom, #43cea2, #185a9d) left,
        linear-gradient(to top, #f953c6, #b91d73) right;
    background-size: 100% 5px, 100% 5px, 5px 100%, 5px 100%;
    background-position: top, bottom, left, right;
    background-repeat: no-repeat;
}

.closeButton {
    position: fixed;
    top: 10px;
    left: 10px;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
    color: #333;
}

.mainTitleWrapper {
    height: 25svh;
    display: flex;
    flex-direction: column;
}

.headerWrapper {
    height: 6svh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 2%;
    font-size: 0.8rem;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.title {
    font-size: 1rem;
    color: #333;
    font-weight: 600;
}

.form {
    padding: 10px 2%;
    display: flex;
    gap: 5px;
    flex-direction: column;
}

.input, .textarea {
    width: calc(100% - 22px);
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 0.8rem;
    color: #333;
}

.saveButton {
    background: #007bff;
    color: white;
    padding: 8px 20px;
    border: none;
    border-radius: 4px;
    font-size: 0.8rem;
    cursor: pointer;
    transition: background 0.3s ease;
    font-weight: 500;
}

.saveButton:hover {
    background: #0056b3;
}

.tableWrapper {
    padding: 0px 2%;
}

.tableContainer {
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow-y: scroll;
    height: 70svh;
}

.row {
    display: flex;
    padding: 10px;
    background: white;
    border-bottom: 1px solid #eee;
    transition: background 0.3s ease;
}

.row:hover {
    background: #f1f1f1;
}

.cell {
    flex: 1;
    padding: 0 10px;
    font-size: 14px;
    color: #333;
    text-align: left;
}

.cell:last-child {
    text-align: right;
}
