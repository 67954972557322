
.mainWrapper {
    display: flex;
    flex-direction: column;
    padding: 1svh 20px;
    background-color: #E8EAED;
    height: 91svh;
    overflow-y: scroll;
}

.headerWrapper {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1rem;
    font-weight: bold;
    color: #343a40;
}

.bodyWrapper {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.leftWrapper {
    flex: 3;
    min-width: 300px;
    gap: 20px;
    display: flex;
    flex-direction: column;
}

.rightWrapper {
    flex: 2;
    min-width: 300px;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.avgRatingWrapper {
    text-align: center;
    margin-bottom: 20px;
}

.avgRating {
    font-size: 2.5rem;
    font-weight: bold;
    color: #007bff;
}
.graphsWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;

    background: #fff;
    padding: 20px;
    border-radius: 8px;
}
.graphWrapper, .trendWrapper, .sentimentWrapper {
    margin-top: 20px;
}
.trendWrapper{
    width: 100%;
}
.graphWrapper{
    padding: 0px 40px;

}
.sentimentWrapper{
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    background-color: #FFFFFF;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    padding: 0px 20px 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.sentimentWrapper p {
    font-size: 1rem;
    margin: 5px 0;
}
.sentimentItem{
    display: flex;
    gap: 10px;
}
@media screen and (max-width: 768px) {
    .bodyWrapper {
        flex-direction: column;
    }
}
