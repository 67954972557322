.mainWrapper {
    position: relative;
    background-color: aliceblue;
    height: 93vh;
}

.mainLoaderWrapper{
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 4;
    height: 0px;
    width: 0px;
    opacity: 0;
    animation: 3.5s appearNow cubic-bezier(0, 0.87, 0.68, 0.95) 1, 1s slideDoorOpen cubic-bezier(0.77, -0.31, 0.23, 1.14) 1;
    -webkit-animation: 3.5s appearNow cubic-bezier(0, 0.87, 0.68, 0.95) 1, 1s slideDoorOpen cubic-bezier(0.77, -0.31, 0.23, 1.14) 1;
    animation-delay: 0s, 3.5s;
    background-color: rebeccapurple;
    overflow: hidden;
}
.mainLoaderContent {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    flex-direction: column;
    height: 93vh;
    background-color: transparent;
}
.contentBox{
    width: 60%;
}
.awesomeTitle{
    font-size: 2rem;
    font-weight: 600;
}
.awesomeSubTitle{
    font-size: 1.4rem;
    font-weight: 500;
}
.mainContent {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    flex-direction: column;
    height: 88vh;
    position: relative;
    color: black;
}


@keyframes appearNow {
    0% {
        opacity: 0;
        background-color: black;
        display: inline-block;
        width: 100%;
        transform: translateY(80%);
        height: 93vh;
        -webkit-transform: translateY(80%);
        -moz-transform: translateY(80%);
        -ms-transform: translateY(80%);
        -o-transform: translateY(80%);
}

    20% {
        opacity: 0;
        background-color: black;
        display: inline-block;
        width: 100%;
        height: 93vh;
        transform: translateY(20%);
        -webkit-transform: translateY(20%);
        -moz-transform: translateY(20%);
        -ms-transform: translateY(20%);
        -o-transform: translateY(20%);
}

    50% {
        opacity: 1;
        transform: translateY(0%);
        background-color: black;
        display: inline-block;
        width: 100%;
        height: 93vh;
    }

    100% {
        opacity: 1;
        transform: translateY(0%);
        background-color: rgb(58, 101, 138);
        display: inline-block;
        width: 100%;
        height: 93vh;
    }
}

@keyframes slideDoorOpen {
    0% {
        width: 100%;
        display: inline-block;
        opacity: 1;
        background-color: rgb(58, 101, 138);
        height: 93vh;
    }
    20% {
        display: inline-block;
        opacity: 1;
        background-color: rgba(58, 101, 138, 0.9);
        height: 93vh;
    }
    40% {
        display: block;
        opacity: 1;
        color: rebeccapurple;
        height: 93vh;
    }
    100% {
        width: 0%;
        display: inline-block;
        opacity: 1;
        background-color: rebeccapurple;
        height: 93vh;
    }
}



.mainContentHeader{
    height: calc(5vh - 1px);
    border-bottom: 1px solid #dfdfdf;
}
.mainContentHeaderContainer{
    height: calc(5vh - 1px);
    display: flex;
    align-items: center;
}
.mainContentHeaderContainer button{
    padding: 2px 10px;
    margin: 0px 6px;
    background-color: #dfdfdf50;
    border: 1px solid #dfdfdf;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    font-size: 0.7rem;
    font-weight: 500;
    color: #00000090;
}





h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    margin: 0px;
    padding: 0px;
}

.formSection {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.formGroup {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

label {
    font-weight: bold;
    color: #555;
}

/* input, select, textarea {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
} */

textarea {
    resize: none;
}

.actions {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}

.actions button {
    border-radius: 4px;
    cursor: pointer;
}

.actions  button:hover {
    background: #eee;
}

.actions button:disabled {
    background: #ccc;
    cursor: not-allowed;
}

.previewSection {
    margin-top: 20px;
    padding: 15px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
}



.mainContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}