.mainWrapper{
    display: flex;
    justify-content: flex-end;
}
.mainContainer{
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    align-items: center;
    padding: 10px 2%;
    flex: 1;
}
.leftCol{
    flex: 3;
}
.rightCol{
    flex: 2;
    display: flex;
    justify-content: flex-end;
}
.storeName{
    font-size: 1rem;
    font-weight: 500;
}
.storeAddress{
    font-size: 0.6rem;
    font-weight: 500;
    color: #818188;
}
.defNav{
    font-weight: 500;
    font-size: 0.7rem;
    border: 1px solid #ffffff;
    background-color: white;
    padding: 4px 16px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.activeNav{
    font-weight: 600;
    font-size: 0.7rem;
    border: 1px solid #027FFF;
    padding: 4px 16px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    background-color: #027FFF;
    color: white;
}