.container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 16px;
    padding: 20px;
    background: linear-gradient(135deg, #f8f9fa, #e9ecef);
    border-radius: 12px;
    /* box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1); */
}

.card {
    background: #ffffff;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease-in-out;
}

.card:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.modeLabel {
    font-size: 0.8rem;
    font-weight: 600;
    margin-bottom: 8px;
    color: #495057;
}

.input {
    padding: 6px 10px;
    font-size: 0.8rem;
    border: 1px solid #ced4da;
    border-radius: 6px;
    transition: all 0.3s ease;
    outline: none;
}

.input:focus {
    border-color: #007bff;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.25);
}

.input::placeholder{
    font-size: 0.6rem;
}