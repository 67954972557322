.tallyWrapper {
    color: #333;
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.header {
    border-bottom: 2px solid #0047ab;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.header h1 {
    color: #0047ab;
    font-size: 24px;
}

.logo {
    max-width: 100px;
    margin-bottom: 10px;
}
.companyDetails {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
}
.invoiceStoreMeta{
    flex: 2;
}
.storeAddress{
    font-size: 0.8rem;
}
.invoiceMetaData{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.invoiceNumber{
    font-size: 0.8rem;
    font-weight: 600;
}
.customerInfo h3 {
    color: #0047ab;
    margin-bottom: 10px;
}

.lineItems table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.lineItems th,
.lineItems td {
    border: 1px solid #ddd;
    padding: 10px;
}

.lineItems tfoot td {
    font-weight: bold;
}

.paymentCollectionWrapper{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.pcwSpace{
    flex: 2;
}
.pcwContent{
    flex: 2;
}
.pcwAmount{
    text-align: right;
}

.footer {
    text-align: center;
    font-size: 12px;
    color: #888;
}






.paymentStatus {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.8rem;
    font-weight: 500;
    padding: 10px;
    border-radius: 8px;
    margin: 10px 0;
    border: 1px solid;
}

.paymentStatus.advance {
    color: #2e7d32;
    background-color: #e8f5e9;
    border-color: #81c784;
}

.paymentStatus.remaining {
    color: #d32f2f;
    background-color: #ffebee;
    border-color: #e57373;
}

.paymentStatus.neutral {
    color: #757575;
    /* Grey for neutral */
    background-color: #f5f5f5;
    border-color: #bdbdbd;
}