.previewOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.previewContent {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 900px;
    display: flex;
    flex-direction: row;
}

.closeBtn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
}

.layout {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.leftColumn {
    width: 40%;
}

.rightColumn {
    width: 55%;
    padding-left: 20px;
}

.rightColumn.whatsapp {
    background-color: #f1f1f1;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #ddd;
}

.rightColumn.sms {
    background-color: #fafafa;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #ddd;
}

.variablesContainer {
    display: flex;
    flex-direction: column;
}

.variableRow {
    margin-bottom: 10px;
}

.variableInput {
    padding: 8px;
    margin-top: 5px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #ddd;
}

.selectButton {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.selectButton:hover {
    background-color: #45a049;
}

.title {
    font-size: 1.2rem;
    font-weight: bold;
}

.messagePreview {
    padding: 15px;
    background-color: #fff;
    border-radius: 8px;
    font-size: 1rem;
}

.icon {
    margin-right: 10px;
}
